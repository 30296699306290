<template>
  <div class="ml-auto py-2 pl-2">
    <div style="margin-right: 3em;">

      <b-form v-if="statsType != 'current-users'" inline>
        <b-input-group class="user-search" v-if="selectedUserType == 'single_user'">
          <AutoComplete :input-attrs="{class: 'form-control'}" v-model="selectedUser" :items="matchedUsers" :get-label="getName" :component-item="template" @update-items="updateUserSearch" :min-len="0" @keyup.enter.native="updateSelectedUser"></AutoComplete>
          <b-button @click.stop="updateSelectedUser">Load</b-button>
        </b-input-group>
        <template v-if="statsType == 'content' || statsType == 'partners'">
          <label class="mr-sm-2 text-secondary" for="stats-select-user-type">User Type</label>
          <b-form-select class="mr-2" id="stats-select-user-type" :value="selectedUserType" @input="updateUserType($event)" :options="userTypeOptions"></b-form-select>
        </template>
        <label class="mr-sm-2 text-secondary" for="stats-select-from">From</label>
        <Datepicker id="stats-select-from" class="mb-2 mr-sm-2 mb-sm-0" :value="from" @input="updateFrom($event)" name="from-date" monday-first></datepicker>
        <label class="mr-sm-2 text-secondary" for="stats-select-from">To</label>
        <Datepicker id="stats-select-to" class="mb-2 mr-sm-2 mb-sm-0" :value="to" @input="updateTo($event)" :disabled-dates="disabledToDates" name="to-date" monday-first></datepicker>
      </b-form>

    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import AutoComplete from "v-autocomplete"
import UserTemplate from "./UserTemplate.vue"

export default {
  name: 'StatsToolbar',

  components: {
    Datepicker,
    AutoComplete
  },

  props: {
    statsType: String
  },

  data() {
    return {
      template: UserTemplate,
      from: null,
      to: null,

      disabledToDates: {to: null},
      userTypeOptions: [
        {value: '__any__', text: 'Any Type'},
        {value: 'associate', text: 'Associate'},
        {value: 'customer', text: 'Customer'},
        {value: 'visitor', text: 'Visitor'},
        {value: 'single_user', text: 'Search User...'}
      ],
      selectedUserType: '__any__',
      selectedUser: {username: '', id: null},
      matchedUsers: []
    }
  },
  
  watch: {
    statsType() {
      this.$root.$emit('stats-date-range', this.from, this.to);
    }

  },

  methods: {
    setDisabledToDates(date) {
      if(date) {
        this.disabledToDates.to = new Date(date)
      } else {
        this.disabledToDates = {to: null}
      }
    },

    setDefaultDateRange() {
      let from = new Date()
      let to = new Date()

      from.setDate(from.getDate() - 8)
      to.setDate(to.getDate() - 1)

      this.from = from
      this.to = to
    },

    getName(user) {
      return user ? user.username : ''
    },

    updateUserSearch(text) {
      fetch(`/admin/api/v2/users/search/${text}`)
      .then(resp => {
        if(resp.status == 200) return resp.json()
        else throw Error(resp.statusText)
      })
      .then(resp => {
        this.matchedUsers = resp.data
      })
      .catch(() => {
        this.$root.app.showAlert('Error loading users', 'danger')
      })

    },

    updateSelectedUser() {
      this.$root.$emit('stats-user-load', this.selectedUser.id);
    },

    updateUserType(type) {
      this.selectedUserType = type
      this.$root.$emit('stats-user-type', type);
    },

    updateFrom(date) {
      this.from = date
      this.$root.$emit('stats-date-range', date, this.to);
      this.setDisabledToDates(date)
    },

    updateTo(date) {
      this.to = date
      this.$root.$emit('stats-date-range', this.from, date);
    },

  },

  created() {
    this.setDefaultDateRange()
  }
}
</script>

<style>
.next {
  color: #E5E9EC !important;
}

.prev {
  color: #E5E9EC !important;
}
</style>
