<template>
  <div>
    <b-tabs @input="activeTab" pills class="mt-3">
      <b-tab title="Content">
        <hr class="mt-3"/>

        <div class="d-flex align-items-center my-4">
          <b-form inline>
            <label class="mr-sm-2" for="stats-select-partner">Partner</label>
            <b-form-select class="mr-2" id="stats-select-partner" :value="params.selectedProvider" @input="updateProvider($event)" :options="partnerOptions"></b-form-select>
            <label class="mr-sm-2 ml-2" for="stats-select-country">Geo Location</label>
            <b-form-select class="mr-2" id="stats-select-country" :value="params.selectedGeo" @input="updateGeo($event)" :options="geoOptions"></b-form-select>
            <label class="mr-sm-2 ml-2" for="stats-select-media">Media Type</label>
            <b-form-select class="" id="stats-select-media" :value="params.selectedMediaType" @input="updateMediaType($event)" :options="mediaTypeOptions"></b-form-select>
          </b-form>

          <template v-if="$config.enableArtistsEverywhere">
            <button @click="$refs.mediaArtistsFilter.show()" class="ml-4 btn dropdown-toggle btn-link" aria-haspopup="true" aria-expanded="false">
              <font-awesome-icon icon="user-group"/>
              <span class="mx-2">Artists</span>
            </button>
          </template>

          <template v-if="$config.enableGenres">
            <button @click="$refs.mediaGenresFilter.show()" class="ml-4 btn dropdown-toggle btn-link" aria-haspopup="true" aria-expanded="false">
              <font-awesome-icon icon="user-group"/>
              <span class="mx-2">Genres</span>
            </button>
          </template>

          <button @click="$refs.mediaLabelFilter.show()" class="ml-2 btn dropdown-toggle btn-link" aria-haspopup="true" aria-expanded="false">
            <font-awesome-icon icon="tags"/>
            <span class="mx-2">Labels</span>
          </button>
        </div>

        <div class="my-4 pt-4">
          <h5>
            <span>Total Views: </span>
            <b-badge>{{ totalCount }}</b-badge>
            <span class="ml-4">Total Duration: </span>
            <b-badge>{{ $helpers.durationToString(totalTime) }}</b-badge>
          </h5>
        </div>

        <a :href="contentStatsHrefData" download="content_stats.xlsx" target="_blank">Download</a>

        <div class="d-flex">
          <b-badge class="mx-1 mt-3" style="opacity: 80%; font-size: 0.9rem;" v-for="label in filterLabels" :key="label.text" variant="dark">
            <Icon v-if="label.icon" v-b-tooltip.hover :title="$helpers.prettify(label.type)" :icon="label.icon"/>
            <span class="mx-1">{{label.text}}</span>
            <b-badge v-if="label.type != 'stats'" href="#" @click.stop="removeLabel(label)">&#x2716;</b-badge>
          </b-badge>
        </div>

        <b-table class="mt-3" :items="contentItems" :fields="contentFields" ref="table" @row-clicked="handleSelectedRow" striped hover small :per-page="perPage" :current-page="currentPage">
          <template v-slot:cell(show_details)="row">
            <div class="d-flex justify-content-center">
              <b-button :id="row.item.media_id" size="sm" variant="primary" class="mr-2 text-white">
                <font-awesome-icon icon="search"/>
               </b-button>
              <b-popover :target="row.item.media_id" custom-class="text-white" triggers="hover" placement="top">
                <template #title>Details</template>
                <div class="text-white">
                  <form autocomplete="off">
                    <b-form-group label="ID" :label-for="`id-input-${row.item.media_id}`" horizontal :label-cols="2">
                      <id-input :id="`id-input-${row.item.media_id}`" :value="row.item.media_id"/>
                    </b-form-group>
                  </form>
                </div>
              </b-popover>
            </div>
          </template>
        </b-table>

        <div v-if="perPage" class="d-flex justify-content-center">
          <b-pagination :total-rows="contentItems.length" :per-page="perPage" v-model="currentPage"/>
        </div>

        <ContentDetailsModal ref="contentDetailsModal"/>
      </b-tab>

      <b-tab title="Partner Content">
        <hr class="mt-3"/>
        <b-form inline class="mt-4">
          <label class="mr-sm-2" for="stats-select-partner-country">Geo Location</label>
          <b-form-select class="mr-2" id="stats-select-partner-country" :value="partnerParams.selectedGeo" @input="updateProviderGeo($event)" :options="geoOptions"></b-form-select>
          <label class="mr-sm-2" for="stats-select-partner-media">Media Type</label>
          <b-form-select class="" id="stats-select-partner-media" :value="partnerParams.selectedMediaType" @input="updateProviderMediaType($event)" :options="mediaTypeOptions"></b-form-select>
        </b-form>

        <b-table class="mt-3" :items="partnerItems" :fields="partnerFields" ref="table" striped hover small :per-page="perPage" :current-page="partnerCurrentPage">
        </b-table>


        <div v-if="perPage" class="d-flex justify-content-center">
          <b-pagination :total-rows="partnerItems.length" :per-page="perPage" v-model="partnerCurrentPage"/>
        </div>
      </b-tab>

      <b-tab title="Users">
        <hr class="mt-3"/>

        <b-form inline>
          <label class="mr-sm-2 ml-2" for="stats-select-user-subscription">Subscription Type</label>
          <b-form-select class="mr-2" id="stats-select-user-subscription" :value="userSubscriptionParams.selectedSubscription" @input="updateUserSubscription($event)" :options="userSubscriptionOptions"></b-form-select>
          <label class="mr-sm-2 ml-2" for="stats-select-user-type">User Type</label>
          <b-form-select class="mr-2" id="stats-select-user-type" :value="userSubscriptionParams.selectedType" @input="updateSubscriptionType($event)" :options="userTypeOptions"></b-form-select>
          <label class="mr-sm-2 ml-2" for="stats-select-user-country">Geo Location</label>
          <b-form-select class="mr-2" id="stats-select-user-country" :value="userSubscriptionParams.selectedGeo" @input="updateUserGeo($event)" :options="geoOptions"></b-form-select>
        </b-form>

        <canvas id="user-chart" class="mt-3"></canvas>
      </b-tab>

      <b-tab title="Current Users">
        <hr class="mt-3"/>
        <b-form class="mb-4" inline>
          <label class="mr-sm-2 ml-2" for="stats-select-current-user-type">User Type</label>
          <b-form-select class="mr-2" id="stats-select-current-user-type" :value="userSubscriptionParams.selectedType" @input="updateSubscriptionType($event)" :options="userTypeOptions"></b-form-select>
          <label class="mr-sm-2 ml-2" for="stats-select-current-user-country">Geo Location</label>
          <b-form-select class="mr-2" id="stats-select-current-user-country" :value="userSubscriptionParams.selectedGeo" @input="updateUserGeo($event)" :options="geoOptions"></b-form-select>
        </b-form>

        <b-card class="m-1">

          <a :href="statsHrefData" download="current_customers_stats.xlsx" target="_blank">Download</a>

          <template v-if="$config.hasPayingCustomers">
            <b-table hover striped fixed :items="currentPremiumCustomers" :fields="currentCustomersFields" caption="Paying customers" caption-top>
              <template v-slot:cell(subscription_name)="row">
                <strong v-if="row.item.subscription_name == 'Subtotal'">{{ row.item.subscription_name }}</strong>
                <span v-else>{{ $helpers.prettify(row.item.subscription_name) }}</span>
              </template>
            </b-table>
          </template>
          <div class="mt-4">
            <b-table hover striped fixed :items="currentFreeCustomers" :fields="currentCustomersFields" :thead-class="$config.hasPayingCustomers ? 'no-head' : ''" caption="Non paying customers" caption-top>
              <template v-slot:cell(subscription_name)="row">
                <strong v-if="row.item.subscription_name == 'Subtotal'">{{ row.item.subscription_name }}</strong>
                <span v-else>{{ $helpers.prettify(row.item.subscription_name) }}</span>
              </template>
            </b-table>
          </div>
          <div v-if="$config.hasPayingCustomers" class="mt-4">
            <b-table hover striped fixed :items="currentTotalCustomers" :fields="currentCustomersFields" thead-class="no-head" caption="All customers" caption-top>
              <template v-slot:cell(subscription_name)="row">
                <strong>{{ $helpers.prettify(row.item.subscription_name) }}</strong>
              </template>
            </b-table>
          </div>
        </b-card>
      </b-tab>
    </b-tabs>

    <EditLabels :labels="params.selectedArtists" @update="updateArtists" ref="mediaArtistsFilter" :allow-new="false" :allow-manage="false" label-type="artist"/>
    <EditLabels :labels="params.selectedGenres" @update="updateGenres" ref="mediaGenresFilter" :allow-new="false" :allow-manage="false" label-type="genre"/>
    <EditLabels :labels="params.selectedLabels" @update="updateLabels" ref="mediaLabelFilter" :allow-new="false" :allow-manage="false" label-type="label"/>

  </div>
</template>

<script>
import ContentDetailsModal from '../components/ContentDetailsModal.vue'
import IdInput from '../components/IdInput.vue'
import EditLabels from '../components/EditLabels.vue'
import Chart from 'chart.js/auto'
/*global ExcelBuilder */

export default {
  name: "Stats",

  components: {
    ContentDetailsModal,
    IdInput,
    EditLabels
  },

  data() {
    let contentFields; 

    if(this.$config.enableArtistsEverywhere) {
      contentFields = [
         {key: 'title', label: 'Title', sortable: true},
         // Add artists column
         {key: 'artists', label: 'Artists', sortable: true, formatter: this.$helpers.arrayFormatter},
         {key: 'genres', label: 'Genres', sortable: true, formatter: this.$helpers.arrayFormatter},
         {key: 'provider', label: 'Partner', sortable: true},
         {key: 'media_type', label: 'Type', sortable: true, formatter: this.$helpers.prettify},
         {key: 'series', label: 'Series', sortable: true},
         {key: 'season', label: 'Season', sortable: true},
         {key: 'total_count', label: 'Views', sortable: true, tdClass: "text-right"},
         {key: 'total_length', label: 'Duration', sortable: true, formatter: this.$helpers.durationToString, tdClass: "text-right"},
         {key: 'density', label: 'Density (%)', sortable: true, formatter: this.$helpers.percentageFormatter, tdClass: "text-right"},
         {key: 'show_details', label: 'Details'}
      ]
    }
    else {
      contentFields = [
         {key: 'title', label: 'Title', sortable: true},
         {key: 'provider', label: 'Partner', sortable: true},
         {key: 'media_type', label: 'Type', sortable: true, formatter: this.$helpers.prettify},
         {key: 'series', label: 'Series', sortable: true},
         {key: 'season', label: 'Season', sortable: true},
         {key: 'total_count', label: 'Views', sortable: true, tdClass: "text-right"},
         {key: 'total_length', label: 'Duration', sortable: true, formatter: this.$helpers.durationToString, tdClass: "text-right"},
         {key: 'density', label: 'Density (%)', sortable: true, formatter: this.$helpers.percentageFormatter, tdClass: "text-right"},
         {key: 'show_details', label: 'Details'}
      ]
    }

    return {
      from: null,
      to: null,

      nowString: null,

      currentCustomersFields: [
        {key: 'subscription_name', label: 'Subscription'},
        {key: 'active', label: 'Active'},
        {key: 'suspended_or_locked', label: 'Suspended or Locked'},
        {key: 'one_time_affiliate', label: 'One Time Affiliate'},
        {key: 'recurring_affiliate', label: 'Recurring Affiliate'}
      ],
      currentCustomersItems: [],

      contentItems: [],
      contentFields: contentFields,

      params: {selectedGeo: "", selectedProvider: '__all__', selectedMediaType: "", selectedArtists: [], selectedGenres: [], selectedLabels: [], selectedUserType: '__any__'},

      partnerItems: [],
      partnerFields: [
         {key: 'name', label: 'Name', sortable: true},
         {key: 'count', label: 'Views', sortable: true, tdClass: "text-right"},
         {key: 'length', label: 'Duration', sortable: true, formatter: this.$helpers.durationToString, tdClass: "text-right"}
      ],

      partnerParams: {selectedGeo: '', selectedMediaType: ''},

      userSubscriptionItems: [],
      userSubscriptionParams: {selectedSubscription: '__any__', selectedGeo: '', selectedType: ''},
  
      selectedUserId: null,

      disabledToDates: {to: null},

      currentPage: 1,
      partnerCurrentPage: 1,
      perPage: 20,

      offers: [],

      geoOptions: [],
      partnerOptions: [],
      mediaTypeOptions: [
        {value: "", text: "Any"},
        {value: "video", text: "Video"},
        {value: "episode", text: "Episode"},
        {value: "book", text: "Book"},
        {value: "issue", text: "Issue"},
        {value: "photo", text: "Photo"},
        {value: "audio", text: "Audio"},
        {value: "podcast_episode", text: "Podcast Episode"},
        {value: "album_track", text: "Album Track"}
      ],
      userTypeOptions: [],
      userSubscriptionOptions: [],
      statsHrefData: null,
      contentStatsHrefData: null,

      userChart: null,
      userChartLabels: [],
      userChartData: []
    }


  },

  computed: {
    filterLabels() {
      const artistLabels = this.params.selectedArtists.map(artist => { return {text: artist, icon: "Menu_Users", type: "artist"} })
      const genreLabels = this.params.selectedGenres.map(genre => { return {text: genre, icon: "labels", type: "genre"} })
      const labelLabels = this.params.selectedLabels.map(label => { return {text: label, icon: "labels", type: "label"} })
      return genreLabels.concat(artistLabels.concat(labelLabels))
    },

    totalCount() {
      let count = 0

      this.contentItems.forEach(item => count += item.total_count)
      
      return count
    },
    
    totalTime() {
      let length = 0

      this.contentItems.forEach(item => length += item.total_length)
      
      return length
    },
    
    currentPremiumCustomers() {
      let subtotal = {
        subscription_name: "Subtotal",
        type: "premium",
        active: 0,
        suspended_or_locked: 0,
        one_time_affiliate: 0,
        recurring_affiliate: 0
      }

      const items = this.currentCustomersItems.filter(item => item.type == "premium")

      items.forEach(item => {
        subtotal.active += item.active
        subtotal.suspended_or_locked += item.suspended_or_locked
        subtotal.one_time_affiliate += item.one_time_affiliate
        subtotal.recurring_affiliate += item.recurring_affiliate
      })      
      
      return items.concat([subtotal])
    },

    currentFreeCustomers() {
      let subtotal = {
        subscription_name: "Subtotal",
        type: "free",
        active: 0,
        suspended_or_locked: 0,
        one_time_affiliate: 0,
        recurring_affiliate: 0
      }

      const items = this.currentCustomersItems.filter(item => item.type == "free")

      items.forEach(item => {
        subtotal.active += item.active
        subtotal.suspended_or_locked += item.suspended_or_locked
        subtotal.one_time_affiliate += item.one_time_affiliate
        subtotal.recurring_affiliate += item.recurring_affiliate
      })      
      
      return items.concat([subtotal])
    },

    currentTotalCustomers() {
      let total = {
        subscription_name: "Total",
        active: 0,
        suspended_or_locked: 0,
        one_time_affiliate: 0,
        recurring_affiliate: 0
      }

      this.currentCustomersItems.forEach(item => {
        total.active += item.active
        total.suspended_or_locked += item.suspended_or_locked
        total.one_time_affiliate += item.one_time_affiliate
        total.recurring_affiliate += item.recurring_affiliate
      })      
      
      return [total]
    }
  },

  methods: {
    activeTab(tabNum) {
      switch(tabNum) {
        case 0:
          this.$root.$emit('set-toolbar', 'stats-toolbar', 'content')
          break

        case 1:
          this.$root.$emit('set-toolbar', 'stats-toolbar', 'partners')
          break

        case 2:
          this.$root.$emit('set-toolbar', 'stats-toolbar', 'users-chart')
          break

        case 3:
          this.$root.$emit('set-toolbar', 'stats-toolbar', 'current-users')
          break
      }
    },

    loadContent() {
      let payload = JSON.stringify({
        data: {
          from: this.$helpers.dateFormatter(this.from),
          to: this.$helpers.dateFormatter(this.to),
          artists: this.params.selectedArtists,
          genres: this.params.selectedGenres,
          labels: this.params.selectedLabels
        }
      })

      if(this.params.selectedUserType == 'single_user') {
        if(this.selectedUserId) {
          fetch(`/admin/api/v2/stats/content/${this.selectedUserId}?geo=${this.params.selectedGeo}&provider=${this.params.selectedProvider}&media_type=${this.params.selectedMediaType}&user_type=${this.params.selectedUserType}`, {method: 'POST', body: payload})
          .then(function(resp) {
            return resp.json();
          })
          .then(resp => {
            this.contentItems = resp.data
            this.contentStatsHrefData = ''
          });
        }
        else {
          this.contentItms = []
          this.contentStatsHrefData = this.contentStatsExcel()
        }
      }
      else {
        fetch(`/admin/api/v2/stats/content?geo=${this.params.selectedGeo}&provider=${this.params.selectedProvider}&media_type=${this.params.selectedMediaType}&user_type=${this.params.selectedUserType}`, {method: 'POST', body: payload})
        .then(function(resp) {
          return resp.json();
        })
        .then(resp => {
          this.contentItems = resp.data.data
          this.contentStatsHrefData = this.contentStatsExcel()
        });
      }
    },

    loadProviders() {
      if(this.params.selectedUserType == 'single_user') {
        this.partnerItems = []
      }
      else {
        let payload = JSON.stringify({
          data: {
            from: this.$helpers.dateFormatter(this.from),
            to: this.$helpers.dateFormatter(this.to)
          }
        })


        fetch(`/admin/api/v2/stats/partners?geo=${this.partnerParams.selectedGeo}&type=${this.partnerParams.selectedMediaType}&user_type=${this.params.selectedUserType}`, {method: 'POST', body: payload})
        .then(function(resp) {
          return resp.json();
        })
        .then(resp => {
          this.partnerItems = resp.data
        });
      }
    },


    loadCurrentUsers() {
      const geo = this.userSubscriptionParams.selectedGeo
      const type = this.userSubscriptionParams.selectedType

      fetch(`/admin/api/v2/stats/current-users?geo=${geo}&type=${type}`)
      .then(resp => {
        if(resp.status == 200) return resp.json()
        else throw Error(resp.statusText)
      })
      .then(resp => {
        this.currentCustomersItems = resp.data
        this.statsHrefData = this.userStatsExcel()
      })
      .catch(e => {
        console.log(e)
        this.$root.app.showAlert('Error loading stats', 'danger')
      })
    },



    loadUserSubscriptions() {
      let payload = JSON.stringify({
        data: {
          from: this.$helpers.dateFormatter(this.from),
          to: this.$helpers.dateFormatter(this.to)
        }
      })

      const geo = this.userSubscriptionParams.selectedGeo
      const type = this.userSubscriptionParams.selectedType
      const subscription_id = this.userSubscriptionParams.selectedSubscription

      fetch(`/admin/api/v2/stats/user-subscriptions?geo=${geo}&type=${type}&subscription_id=${subscription_id}`, {method: 'POST', body: payload})
      .then(function(resp) {
        return resp.json()
      })
      .then(resp => {
        this.userSubscriptionItems = resp.data
        this.userChartData.data.labels = resp.data.map(item => item[0])
        this.userChartData.data.datasets[0].data = resp.data.map(item => item[1])
        this.userChart.update()
      });

    },

    loadOffers() {
      fetch('/admin/api/v2/stats/offers')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.offers = resp.data
      });
    },

    loadUserTypeOptions() {
      fetch('/admin/api/v2/users/type-options')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.userTypeOptions = [{value: '', text: 'Any Type'}].concat(resp.data)
      })
    },

    loadUserSubscriptionOptions() {
      fetch('/admin/api/v2/stats/subscription-options')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.userSubscriptionOptions = [{value: '', text: 'Any Subscription'}].concat(resp.data)
      })
    },

    getProviderOptions() {
      fetch("/admin/api/v2/partners/select-options?include-all")
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.partnerOptions = resp.data
        this.getGeos()
      });
    },

    getGeos() {
      fetch('/admin/api/v2/stats/geo-options?include-world')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.geoOptions = resp.data;
      });
    },

    removeLabel(label) {
      if(label.type == "artist") {
        this.params.selectedArtists = this.params.selectedArtists.filter(a => a != label.text)
      }
      else if(label.type == "genre") {
        this.params.selectedGenres = this.params.selectedGenres.filter(a => a != label.text)
      }
      else if(label.type == "label") {
        this.params.selectedLabels = this.params.selectedLabels.filter(l => l != label.text)
      }
    },

    handleSelectedRow(row) {
      this.$refs.contentDetailsModal.show(row.title, this.$_.values(row.geos))
    },

    updateProvider(partner) {
      this.params.selectedProvider = partner
      this.loadContent()
    },

    updateGeo(geo) {
      this.params.selectedGeo = geo
      this.loadContent()
    },

    updateMediaType(type) {
      this.params.selectedMediaType = type
      this.loadContent()
    },

    updateUserContent(userId) {
      this.selectedUserId = userId
      this.loadContent()
      this.loadProviders()
    },

    updateUserType(type) {
      this.params.selectedUserType = type
      this.loadContent()
      this.loadProviders()
    },

    updateArtists(artists) {
      this.params.selectedArtists = artists
      this.loadContent()
    },

    updateGenres(genres) {
      this.params.selectedGenres = genres
      this.loadContent()
    },

    updateLabels(labels) {
      this.params.selectedLabels = labels
      this.loadContent()
    },

    updateProviderGeo(geo) {
      this.partnerParams.selectedGeo = geo
      this.loadProviders()
    },

    updateUserGeo(geo) {
      this.userSubscriptionParams.selectedGeo = geo
      this.loadUserSubscriptions()
      this.loadCurrentUsers()
    },

    updateSubscriptionType(type) {
      this.userSubscriptionParams.selectedType = type
      this.loadUserSubscriptions()
      this.loadCurrentUsers()
    },

    updateUserSubscription(subscription_id) {
      this.userSubscriptionParams.selectedSubscription = subscription_id
      this.loadUserSubscriptions()
    },

    updateRange(from, to) {
      this.from = from
      this.to = to
      this.loadContent()
      this.loadProviders()
      this.loadUserSubscriptions()
    },

    userStatsExcel() {
      let workbook = ExcelBuilder.createWorkbook();
      let sheet = workbook.createWorksheet({name: "CurrentCustomers"});
      let stylesheet = workbook.getStyleSheet();
      let boldFormatter = stylesheet.createFormat({
        font: {
          bold: true,
          size: 11
        }
      });

      const header = [
        [{value: `Current Customers ${new Date().toLocaleString()}`, metadata: {style: boldFormatter.id}}],
        []
      ]

      const paidHeader = [
        [{value: "Paid Customers", metadata: {style: boldFormatter.id}}],
        [],
        [
          {value: "Subscription", metadata: {style: boldFormatter.id}},
          {value: "Active", metadata: {style: boldFormatter.id}},
          {value: "Susp./Locked", metadata: {style: boldFormatter.id}},
          {value: "OneTime Af.", metadata: {style: boldFormatter.id}},
          {value: "Recurring Af.", metadata: {style: boldFormatter.id}}
        ]
      ]
      
      const paidItems = this.currentPremiumCustomers.map(item => [
        this.$helpers.prettify(item.subscription_name),
        item.active,
        item.suspended_or_locked,
        item.one_time_affiliate,
        item.recurring_affiliate
      ])

      const freeHeader = [
        [],
        [{value: "Non paying Customers", metadata: {style: boldFormatter.id}}],
        [],
        [
          {value: "Subscription", metadata: {style: boldFormatter.id}},
          {value: "Active", metadata: {style: boldFormatter.id}},
          {value: "Susp./Locked", metadata: {style: boldFormatter.id}},
          {value: "OneTime Af.", metadata: {style: boldFormatter.id}},
          {value: "Recurring Af.", metadata: {style: boldFormatter.id}}
        ]
      ]
      
      const freeItems = this.currentFreeCustomers.map(item => [
        this.$helpers.prettify(item.subscription_name),
        item.active,
        item.suspended_or_locked,
        item.one_time_affiliate,
        item.recurring_affiliate
      ])

      const allHeader = [
        [],
        [{value: "All Customers", metadata: {style: boldFormatter.id}}],
        [],
        [
          {value: "Subscription", metadata: {style: boldFormatter.id}},
          {value: "Active", metadata: {style: boldFormatter.id}},
          {value: "Susp./Locked", metadata: {style: boldFormatter.id}},
          {value: "OneTime Af.", metadata: {style: boldFormatter.id}},
          {value: "Recurring Af.", metadata: {style: boldFormatter.id}}
        ]
      ]
      
      const allItems = this.currentTotalCustomers.map(item => [
        this.$helpers.prettify(item.subscription_name),
        item.active,
        item.suspended_or_locked,
        item.one_time_affiliate,
        item.recurring_affiliate
      ])

      const rows = header.concat(paidHeader).concat(paidItems).concat(freeHeader).concat(freeItems).concat(allHeader).concat(allItems)

      sheet.setData(rows)
      workbook.addWorksheet(sheet);

      return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + ExcelBuilder.createFile(workbook)
    },

    contentStatsExcel() {
      let workbook = ExcelBuilder.createWorkbook();
      let sheet = workbook.createWorksheet({name: "ContentStats"});
      let stylesheet = workbook.getStyleSheet();
      let boldFormatter = stylesheet.createFormat({
        font: {
          bold: true,
          size: 11
        }
      });

      const title = `${this.$config.name} Content Stats ${this.$helpers.dateFormatter(this.from)} - ${this.$helpers.dateFormatter(this.to)}`
      const selectedPartner = this.params.selectedProvider == '__all__' ? "All" : this.$store.state.partner.values[this.params.selectedProvider].name
      const selectedGeo = this.params.selectedGeo == '' ? "All" : this.params.selectedGeo
      const selectedMediaType = this.params.selectedMediaType == '' ? "All" : this.$helpers.prettify(this.params.selectedMediaType)
      const selectedArtists = this.params.selectedArtists.length > 0 ? this.params.selectedArtists.join(', ') : 'All'
      const selectedGenres = this.params.selectedGenres.length > 0 ? this.params.selectedGenres.join(', ') : 'All'
      const selectedLabels = this.params.selectedLabels.length > 0 ? this.params.selectedLabels.join(', ') : 'All'

      const header1 = [[{value: title, metadata: {style: boldFormatter.id}}], []]
      const header2 = [[{value: 'Partner', metadata: {style: boldFormatter.id}}, null, selectedPartner]]
      const header3 = [[{value: 'Country', metadata: {style: boldFormatter.id}}, null, selectedGeo]]
      const header4 = [[{value: 'Media Type', metadata: {style: boldFormatter.id}}, null, selectedMediaType]]
      const header5 = [[{value: 'Artists', metadata: {style: boldFormatter.id}}, null, selectedArtists]]
      const header6 = [[{value: 'Genres', metadata: {style: boldFormatter.id}}, null, selectedGenres]]
      const header7 = [[{value: 'Labels', metadata: {style: boldFormatter.id}}, null, selectedLabels]]

      const header8 = [
        [],
        [{value: 'Total Views', metadata: {style: boldFormatter.id}}, null, this.totalCount],
        [{value: 'Total Duration', metadata: {style: boldFormatter.id}}, null, this.$helpers.durationToString(this.totalTime)],
        [],
        [
          {value: 'Title', metadata: {style: boldFormatter.id}},
          {value: 'Artists', metadata: {style: boldFormatter.id}},
          {value: 'Genres', metadata: {style: boldFormatter.id}},
          {value: 'Partner', metadata: {style: boldFormatter.id}},
          {value: 'Type', metadata: {style: boldFormatter.id}},
          {value: 'Series', metadata: {style: boldFormatter.id}},
          {value: 'Season', metadata: {style: boldFormatter.id}},
          {value: 'Views', metadata: {style: boldFormatter.id}},
          {value: 'Duration', metadata: {style: boldFormatter.id}},
          {value: 'Density (%)', metadata: {style: boldFormatter.id}}
        ]
      ]

      const body = this.contentItems.map(item => {
        return [
          item.title,
          item.artists ? item.artists.join(', ') : '',
          item.genres ? item.genres.join(', ') : '',
          item.provider,
          item.media_type,
          item.series,
          item.season,
          item.total_count,
          this.$helpers.durationToString(item.total_length),
          this.$helpers.percentageFormatter(item.density)
        ]
      })

      const rows = header1.concat(header2).concat(header3).concat(header4).concat(header5).concat(header6).concat(header7).concat(header8).concat(body)

      sheet.setData(rows)
      workbook.addWorksheet(sheet);

      return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + ExcelBuilder.createFile(workbook)
    }

  },

  mounted() {
    this.userChartData = {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Active Users',
            data: []
          }
        ]
      },
      options: {
        scales: {
          y: {
            ticks: {
              callback: value => { if (value % 1 === 0) {return value;} }
            }
          }
        }
      }
    }
    
    this.userChart = new Chart(
      document.getElementById('user-chart'),
      this.userChartData
    )        
    this.nowString = new Date().toISOString()
    this.$root.app.setActivePageTitle("Stats", true)
    this.$root.$emit('set-toolbar', 'stats-toolbar')
    this.getProviderOptions()
    this.loadUserSubscriptionOptions()
    this.loadUserTypeOptions()
    this.loadOffers()
    this.loadCurrentUsers()
  },

  unmounted() {
    this.userChart.destroy()
  },

  created() {

    this.$root.$on('stats-date-range', this.updateRange)
    this.$root.$on('stats-user-type', this.updateUserType)
    this.$root.$on('stats-user-load', this.updateUserContent)
  },

  beforeDestroy() {
    this.$root.$off('stats-date-range', this.updateRange)
    this.$root.$off('stats-user-type', this.updateUserType)
    this.$root.$off('stats-user-load', this.updateUserContent)
  }
};

</script>
