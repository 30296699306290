var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form",
                { staticClass: "category-edit-form shadow-sm my-2" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Category Labels",
                        "label-for": "labels-comp",
                        horizontal: "",
                        "label-cols": 2,
                      },
                    },
                    [
                      _c("LabelsInput", {
                        attrs: {
                          id: "labels-comp",
                          language: _vm.language,
                          labels: _vm.categoryFilterLabels,
                          allowNew: false,
                        },
                        on: { update: _vm.saveEditLabels },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Category Artists",
                        "label-for": "artists-comp",
                        horizontal: "",
                        "label-cols": 2,
                      },
                    },
                    [
                      _c("LabelsInput", {
                        attrs: {
                          id: "artists-comp",
                          language: _vm.language,
                          labels: _vm.categoryFilterArtists,
                          allowNew: false,
                          "label-type": "artist",
                        },
                        on: { update: _vm.saveEditArtists },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Category Genres",
                        "label-for": "genres-comp",
                        horizontal: "",
                        "label-cols": 2,
                      },
                    },
                    [
                      _c("LabelsInput", {
                        attrs: {
                          id: "genres-comp",
                          language: _vm.language,
                          labels: _vm.categoryFilterGenres,
                          allowNew: false,
                          "label-type": "genre",
                        },
                        on: { update: _vm.saveEditGenres },
                      }),
                    ],
                    1
                  ),
                  _vm.type == "playlist_page"
                    ? [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Content Format",
                              horizontal: "",
                              "label-for": "content-format-select",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "content-format-select",
                                options: [
                                  { value: "audio", text: "Audio" },
                                  { value: "video", text: "Video" },
                                ],
                              },
                              model: {
                                value: _vm.assetFormat,
                                callback: function ($$v) {
                                  _vm.assetFormat = $$v
                                },
                                expression: "assetFormat",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Content Type",
                        horizontal: "",
                        "label-cols": 2,
                      },
                    },
                    [
                      _c(
                        "b-form-checkbox-group",
                        {
                          attrs: { stacked: "" },
                          model: {
                            value: _vm.categoryFilterType,
                            callback: function ($$v) {
                              _vm.categoryFilterType = $$v
                            },
                            expression: "categoryFilterType",
                          },
                        },
                        [
                          _c("b-form-checkbox", { attrs: { value: "" } }, [
                            _c("em", [_vm._v("Select all")]),
                          ]),
                          _vm.type == "playlist_page" &&
                          _vm.assetFormat == "audio"
                            ? [
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "album_track" } },
                                  [_vm._v("Album Track")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "audio" } },
                                  [_vm._v("Audio")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "podcast_episode" } },
                                  [_vm._v("Podcast Episode")]
                                ),
                              ]
                            : _vm.type == "playlist_page" &&
                              _vm.assetFormat == "video"
                            ? [
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "episode" } },
                                  [_vm._v("Episode")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "video" } },
                                  [_vm._v("Video")]
                                ),
                              ]
                            : [
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "artist_page" } },
                                  [_vm._v("Artist Page")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "audio" } },
                                  [_vm._v("Audio")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "book" } },
                                  [_vm._v("Book")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "brand" } },
                                  [_vm._v("Brand")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "external" } },
                                  [_vm._v("External")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "magazine" } },
                                  [_vm._v("Magazine")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "music_album" } },
                                  [_vm._v("Music Album")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "page" } },
                                  [_vm._v("Micro Page")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "photo" } },
                                  [_vm._v("Photo")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "playlist_page" } },
                                  [_vm._v("Playlist Page")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "podcast" } },
                                  [_vm._v("Podcast")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "series" } },
                                  [_vm._v("Series")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  { attrs: { value: "video" } },
                                  [_vm._v("Video")]
                                ),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: { label: "Plan", horizontal: "", "label-cols": 2 },
                    },
                    _vm._l(
                      _vm.$store.state.media.options.plan,
                      function (option) {
                        return _c(
                          "b-form-checkbox-group",
                          {
                            key: option.value,
                            model: {
                              value: _vm.categoryFilterPlan,
                              callback: function ($$v) {
                                _vm.categoryFilterPlan = $$v
                              },
                              expression: "categoryFilterPlan",
                            },
                          },
                          [
                            _c(
                              "b-form-checkbox",
                              { attrs: { value: option.value } },
                              [_vm._v(_vm._s(option.text))]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _vm.format != "category"
                ? _c(
                    "b-form",
                    { staticClass: "category-edit-form shadow-sm my-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Rotation Interval (in days)",
                            horizontal: "",
                            "label-cols": 2,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.categoryFilterRotateInterval,
                              callback: function ($$v) {
                                _vm.categoryFilterRotateInterval = $$v
                              },
                              expression: "categoryFilterRotateInterval",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Rotation Amount (in positions)",
                            horizontal: "",
                            "label-cols": 2,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.categoryFilterRotateSteps,
                              callback: function ($$v) {
                                _vm.categoryFilterRotateSteps = $$v
                              },
                              expression: "categoryFilterRotateSteps",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-start align-items-center mb-2",
                },
                [
                  _c("h5", { staticClass: "text-weight-bold" }, [
                    _vm._v("Filters"),
                  ]),
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Livestream",
                    "label-for": "category-filters-livestream",
                    horizontal: "",
                    "label-cols": 2,
                  },
                },
                [
                  _c("b-form-checkbox", {
                    attrs: { id: "category-filters-livestream", switch: "" },
                    model: {
                      value: _vm.categoryFiltersLivestream,
                      callback: function ($$v) {
                        _vm.categoryFiltersLivestream = $$v
                      },
                      expression: "categoryFiltersLivestream",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Quality Stamp",
                    "label-for": "category-filters-quality-stamp",
                    horizontal: "",
                    "label-cols": 2,
                  },
                },
                [
                  _c("b-form-checkbox", {
                    attrs: { id: "category-filters-quality-stamp", switch: "" },
                    model: {
                      value: _vm.categoryFiltersQualityStamp,
                      callback: function ($$v) {
                        _vm.categoryFiltersQualityStamp = $$v
                      },
                      expression: "categoryFiltersQualityStamp",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Recently Added",
                    "label-for": "category-filters-recently-added",
                    horizontal: "",
                    "label-cols": 2,
                  },
                },
                [
                  _c("b-form-checkbox", {
                    attrs: {
                      id: "category-filters-recently-added",
                      switch: "",
                    },
                    model: {
                      value: _vm.categoryFiltersRecentlyAdded,
                      callback: function ($$v) {
                        _vm.categoryFiltersRecentlyAdded = $$v
                      },
                      expression: "categoryFiltersRecentlyAdded",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Shopping Enabled",
                    "label-for": "category-filters-shopping-enabled",
                    horizontal: "",
                    "label-cols": 2,
                  },
                },
                [
                  _c("b-form-checkbox", {
                    attrs: {
                      id: "category-filters-shopping-enabled",
                      switch: "",
                    },
                    model: {
                      value: _vm.categoryFiltersShoppingEnabled,
                      callback: function ($$v) {
                        _vm.categoryFiltersShoppingEnabled = $$v
                      },
                      expression: "categoryFiltersShoppingEnabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.format != "category"
        ? _c(
            "b-form-group",
            { attrs: { horizontal: "", "label-cols": 2 } },
            [
              _c(
                "b-form",
                {
                  staticClass: "d-flex flex-row-reverse",
                  attrs: { inline: "" },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      disabled:
                        this.mediaEdit.category_filter.options.includes(
                          "recently_added"
                        ),
                      id: "sort-direction-select",
                      options: _vm.sortDirectionOptions,
                    },
                    model: {
                      value: _vm.categoryFilterSortDirection,
                      callback: function ($$v) {
                        _vm.categoryFilterSortDirection = $$v
                      },
                      expression: "categoryFilterSortDirection",
                    },
                  }),
                  _c("b-form-select", {
                    attrs: {
                      disabled:
                        this.mediaEdit.category_filter.options.includes(
                          "recently_added"
                        ),
                      id: "sort-type-select",
                      options: _vm.sortTypeOptions,
                    },
                    model: {
                      value: _vm.categoryFilterSortType,
                      callback: function ($$v) {
                        _vm.categoryFilterSortType = $$v
                      },
                      expression: "categoryFilterSortType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.format != "category"
        ? _c("b-table", {
            attrs: { hover: "", items: _vm.items, fields: _vm.fields },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(position)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-end",
                          staticStyle: { "max-width": "80px" },
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(row.index + 1) + "."),
                          ]),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                id: "cat-actions-" + row.item.id,
                                variant: row.item.isFixed
                                  ? "warning"
                                  : "secondary",
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleFixedPosition(row.item)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: row.item.isFixed ? "lock" : "lock-open",
                                  size: "sm",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "cat-actions-" + row.item.id,
                            show: row.item._actionsVisible,
                            placement: "left",
                          },
                          on: {
                            "update:show": function ($event) {
                              return _vm.$set(
                                row.item,
                                "_actionsVisible",
                                $event
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "Set position",
                                  value: _vm.getFixedPosition(row.item),
                                },
                                on: {
                                  update: function ($event) {
                                    return _vm.setCurrentIndex($event, row.item)
                                  },
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { size: "sm", variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateFixedPosition(
                                            row.item
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Apply ")]
                                  ),
                                ],
                                1
                              ),
                              row.item.isFixed
                                ? _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { size: "sm" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.unsetFixedPosition(
                                                row.item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Unlock ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(publish_status)",
                  fn: function (row) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "rounded",
                          class: _vm.publishClass(row.value),
                        },
                        [_vm._v(_vm._s(row.value))]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              317816813
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }