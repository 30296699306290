import Vue from 'vue'
import every from "lodash/every"
import includes from "lodash/includes"
import merge from "lodash/merge"
import union from "lodash/union"
import difference from "lodash/difference"
import lowerCase from "lodash/lowerCase"
import cloneDeep from "lodash/cloneDeep"

import helpers from '../Helpers'
import config from '../config'

const util = {
  getSortFunction(sortOrder) {
    switch(sortOrder) {
      case "title-asc":
        return (a, b) => {
          let at = lowerCase(!a.meta ? "" : a.meta.title ? a.meta.title : "")
          let bt = lowerCase(!b.meta ? "" : b.meta.title ? b.meta.title : "")
          return at < bt ? -1 : at > bt ? 1 : 0

        }

      case "title-desc":
        return (a, b) => {
          let at = lowerCase(!a.meta ? "" : a.meta.title ? a.meta.title : "")
          let bt = lowerCase(!b.meta ? "" : b.meta.title ? b.meta.title : "")
          return at < bt ? 1 : at > bt ? -1 : 0

        }

      case "date-asc":
        return (a, b) => {
          let at = a.schedule_from
          let bt = b.schedule_from
          return at < bt ? -1 : at > bt ? 1 : 0

        }

      case "date-desc":
        return (a, b) => {
          let at = a.schedule_from
          let bt = b.schedule_from
          return at < bt ? 1 : at > bt ? -1 : 0

        }

      case "ranked":
        return null;
    }
  },

  getParentCrumbs(media) {
    let crumbs = ''
    let parent = media.parent

    while(parent) {
      crumbs = parent.meta ? (crumbs + ` ➜ ${parent.meta.title}`) : crumbs
      parent = parent.parent
    }

    return crumbs
  },

  assetFormat(item) {
    if(item.type == "video" || item.type == "series" || item.type == "season" || item.type == "episode") {
      if(item.asset_source == "internal") { return "video"; }
      else if(item.asset_source == "external") { return "external_video"; }
    }
    else if(item.type == "book" || item.type == "magazine" || item.type == "year" || item.type == "issue") {
      if(item.asset_source == "internal") { return "print"; }
      else if(item.asset_source == "external") { return "external_print"; }
    }
    else if(item.type == "audio" || item.type == "music_album" || item.type == "album_track" || item.type == "podcast" || item.type == "podcast_episode") {
      if(item.asset_source == "internal") { return "audio"; }
      else if(item.asset_source == "external") { return "external_audio"; }
    }
    else if(item.type == "photo") {
      if(item.asset_source == "internal") { return "image"; }
      else if(item.asset_source == "external") { return "image"; }
    }
    else { return null; }
  },

  sortOrderOptions(searchMode) {
    if(searchMode) {
      return [
        {value: "ranked", text: "Ranked"},
        {value: "title-asc", text: "Title ascending"},
        {value: "title-desc", text: "Title descending"},
        {value: "date-asc", text: "Date ascending"},
        {value: "date-desc", text: "Date descending"}
      ]
    }
    else {
      return [
        {value: "title-asc", text: "Title ascending"},
        {value: "title-desc", text: "Title descending"},
        {value: "date-asc", text: "Date ascending"},
        {value: "date-desc", text: "Date descending"}
      ]
    }
  },

  categorySortOrderOptions(searchMode){
    if(searchMode) {
      return [
        {value: "ranked", text: "Ranked"},
        {value: "title-asc", text: "Title ascending"},
        {value: "title-desc", text: "Title descending"}
      ]
    }
    else {
      return [
        {value: "title-asc", text: "Title ascending"},
        {value: "title-desc", text: "Title descending"}
      ]
    }
  },

  getActiveTypes(typeFilter) {
    let types = []

    for (const [type, isActive] of Object.entries(typeFilter)) {
      if(isActive) types.push(type)
    }

    return types
  }
}


export default {
  namespaced: true,

  state: () => ({
    items: [],
    categories: [],

    defaultAudioTranscodePreset: null,
    defaultVideoTranscodePreset: null,

    options: {
      asset: [
        {value: "internal", text: "Internal"},
        {value: "external", text: "External"}
      ],

      format: [
        {value: "cover", text: "Cover"},
        {value: "thumbnail", text: "Direct Play"},
        {value: "category", text: "Streams Cover Category"},
        {value: "category_grid", text: "Grid Cover Category"},
      ],

      formatSize: [
        {value: "md", text: "Medium"},
        {value: "lg", text: "Large"}
      ],

      formfactor: [
        {value: "portrait", text: "Portrait"},
        {value: "landscape", text: "Landscape"},
        {value: "square", text: "Square"}
      ],

      categoryType: [
        {value: "dynamic", text: "Dynamic"},
        {value: "manual", text: "Manual"},
        {value: "recently-added", text: "Recently Added"},
        {value: "continue-watching", text: "Continue Watching"},
        {value: "remainder", text: "Remainder"}
      ],

      playlistPageCategoryType: [
        {value: "dynamic", text: "Dynamic"},
        {value: "manual", text: "Manual"}
      ],

      categoryAdMode: [
        {value: "default", text: "Default"},
        {value: "playlist", text: "Playlist"}
      ],

      sortOrder: util.sortOrderOptions(),

      categorySortOrder: util.categorySortOrderOptions(),

      // fetch from backend
      plan: [],
      partner: [],
      partnerFilter: [],
      language: [],
      premiumValue: [],
      audioTranscodePresets: [],
      videoTranscodePresets: [],

      serialLayout: [
        {value: "grid_dropdown", text: "Dropdown with a Grid"},
        {value: "carousel_dropdown", text: "Dropdown with a Carousel"},
        {value: "grid_page", text: "Page with Grids"},
        {value: "carousel_page", text: "Page with Carousels"}
      ]
    },

    typeFilter: {
      audio: true,
      artist_page: true,
      book: true,
      brand: true,
      external: true,
      magazine: true,
      music_album: true,
      page: true,
      photo_package: true,
      playlist_page: true,
      podcast: true,
      series: true,
      video: true
    },

    searchTypeFilter: {
      album_track: true,
      artist_page: true,
      audio: true,
      book: true,
      brand: true,
      episode: true,
      external: true,
      issue: true,
      magazine: true,
      music_album: true,
      page: true,
      photo: true,
      photo_package: true,
      playlist_page: true,
      podcast: true,
      podcast_episode: true,
      season: true,
      series: true,
      video: true,
      year: true
    },
    labelFilter: [],
    artistsFilter: [],
    genresFilter: [],
    publishStatusFilter: {
      incomplete: true,
      complete: true,
      overdue: true,
      upcoming: true,
      live: true,
      done: true,
      invalid: true
    },
    searchFilter: null,
    searchFilterString: null,
    searchCategoriesString: null,
    searchMediaString: null,
    partnerFilter: "__all__",
    sortOrder: "title-asc",
    categorySortOrder: "title-asc",
    sorter: util.getSortFunction("title-asc"),
    categorySorter: util.getSortFunction("title-asc"),
    searchMode: false,

    selectedNodes: [],

    categoryIdToCopy: null,

    expanded: {},

    edits: {},

    new: {
      item: {
        id: null,
        parent: null,
        reporting_code: null,
        upc: null,
        isrc: [],
        title: null,
        type: null,
        partner_id: null,
        artist: null,
        artists: [],
        genres: [],
        no_rev_share: null,
        premium_value: 100,
        default_language: "en",
        asset_source: "internal",
        labels: [],
        plan: "", // fetch from backend
        quality_stamp: false,
        year: null,
        schedule_from: null,
        schedule_to: null,
        geo_whitelist: ["world"],
        geo_blacklist: [],
        category_type: "dynamic",
        category_ad_mode: "default",
        category_layout: {
          format: "cover",
          size: config.defaultCoverSize || "md",
          formfactor: "portrait"
        },
        link_to: "",
        recently_added_exclude: false,
        call_to_action_enabled: false,
        shopping_enabled: true,
        show_meta_text: true,
        cover_enabled: true,
        page_enabled: true,
        header_overflow: true,
        slide_enabled: config.slideEnabled,
        allow_archiving: true,
        is_kids_content: false,
        is_livestream: false,
        datetime_livestream_start: null,
        datetime_livestream_end: null
      },
      hasParent: false
    },

    modals: {
      new: {
        visible: false
      },
      archive: {
        visible: false
      },
      import: {
        visible: false,
        insert: false
      },
      duplicate: {
        visible: false,
        id: null
      },
      publishCheckResults: {
        visible: false,
        data: {
          status: "",
          mediaId: null,
          isSaveResult: false,
          headerText: "",
          subHeaderText: "",
          results: []
        }
      },
      labels: {
        visible: false
      },
      artists: {
        visible: false
      },
      genres: {
        visible: false
      },
      properties: {
        visible: false
      }
    },

    toArchiveMediaIds: [],

    managedLabels: [],
    managedArtists: [],
    managedGenres: [],

    // fetch from backend
    premiumTypes: [],
    defaultPlan: ""
  }),

  getters: {
    labelMatcher: (state, getters) => (items) => {
      return items.filter(item => getters.labelMatch(item))
    },

    labelMatch: state => (item) => {
      if(state.labelFilter.length > 0) {
        return every(state.labelFilter, label => includes(item.labels, label))
      }
      else return true
    },

    artistMatcher: (state, getters) => (items) => {
      return items.filter(item => getters.artistMatch(item))
    },

    artistMatch: state => (item) => {
      if(state.artistFilter.length > 0) {
        return every(state.artistFilter, artist => includes(item.artists, artist))
      }
      else return true
    },

    genreMatcher: (state, getters) => (items) => {
      return items.filter(item => getters.genreMatch(item))
    },

    genreMatch: state => (item) => {
      if(state.genreFilter.length > 0) {
        return every(state.genreFilter, genre => includes(item.genres, genre))
      }
      else return true
    },


    typeAllSet: state => {
      let allSet = true
      const filter = state.searchMode ? state.searchTypeFilter : state.typeFilter

      for(const type in filter) {
        if(!filter[type]) allSet = false
      }

      return allSet
    },

    typeNoneSet: state => {
      let allOff = true
      const filter = state.searchMode ? state.searchTypeFilter : state.typeFilter

      for(const type in filter) {
        if(filter[type]) allOff = false
      }

      return allOff
    },


    typeMatch: state => (item) => {
      const filter = state.searchMode ? state.searchTypeFilter : state.typeFilter

      if(filter) return filter[item.type]
      else return true
    },

    publishStatusMatch: state => (item) => {
      const filter = state.publishStatusFilter

      if(filter) return filter[item.publish_status]
      else return true
    },

    publishStatusAllSet: state => {
      let allSet = true

      for(const publishStatus in state.publishStatusFilter) {
        if(!state.publishStatusFilter[publishStatus]) allSet = false
      }

      return allSet
    },

    searchMatch: state => (item, searchCrumbs) => {
      const filter = state.searchFilter;

      const artistMatch = (item.artists || []).some(artist => artist.match(filter));
      const genreMatch = (item.genres || []).some(genre => genre.match(filter));

      if (filter) {
        if (searchCrumbs) {
          return (
              (item.meta.title && item.meta.title.match(filter)) ||
              item.id.match(filter) ||
              item.parent_crumbs.match(filter) ||
              artistMatch || genreMatch
          );
        } else {
          return (
              (item.meta.title && item.meta.title.match(filter)) ||
              item.id.match(filter) ||
              artistMatch || genreMatch
          );
        }
      } else {
        return true;
      }
    },

    editDiff: state => (id) => {
      const editor = state.edits[id]
      if(editor) {
        const res = helpers.makeDiff(
          editor.org,
          editor.edit,
          ['id', 'default_language', 'type', 'language'],
          ['covers', 'slides', 'thumbnails', 'assets', 'subtitles', 'dashboard', 'playlists', 'children', 'has_dashboard', 'has_playlists', 'category_content'],
          ['category_filter', 'category_items', 'fixed_items', 'category_layout'],
          {meta: "language", category_items: "id", fixed_items: "id"}
        )
        return res
      }
    }
  },

  mutations: {


    putPremiumValueOptions(state, options) {
      state.options.premiumValue = options
    },

    putTranscodeOptions(state, opts) {
      state.defaultAudioTranscodePreset = opts.defaultAudioPreset
      state.defaultVideoTranscodePreset = opts.defaultVideoPreset
      state.options.audioTranscodePresets = opts.audioPresets
      state.options.videoTranscodePresets = opts.videoPresets
    },


    putPremiumTypes(state, types) {
      state.premiumTypes = types
    },

    putPlanOptions(state, options) {
      state.options.plan = options
    },

    putDefaultPlan(state, plan) {
      state.defaultPlan = plan
      state.new.plan = plan
    },

    putItems(state, items) {
      state.items = items
    },

    putCategories(state, categories) {
      state.categories = categories.map(m => {
        m.parent_crumbs = util.getParentCrumbs(m)
        return m
      })
    },

    putExpandedItem(state, item) {
      Vue.set(state.expanded, item.id, item);
    },

    applyExpandedItemReordering(state, id) {
      const expanded = state.expanded[id]
      if(expanded && expanded.children.length > 0) {
        expanded.children = expanded.children.map((m, ndx) => {
          m.serial_nr = ndx + 1
          return m
        })
        state.expanded[id] = expanded
      }
    },

    restoreExpandedItemReordering(state, id) {
      const expanded = state.expanded[id]
      if(expanded && expanded.children.length > 0) {
        expanded.children.sort((a, b) => {
          if (a.serial_nr < b.serial_nr)
            return -1

          if (a.serial_nr > b.serial_nr)
            return 1

          return 0
        })
        state.expanded[id] = expanded
      }
    },

    clearExpandedItem(state, itemId) {
      Vue.delete(state.expanded, itemId);
    },

    putEditItem(state, item) {
      if(item.propertyUpdate && state.edits[item.id] && state.edits[item.id].edit) {
        delete item.propertyUpdate
        Vue.set(state.edits[item.id], 'edit', item);
      }
      else {
        let editor = state.edits[item.id]
        const lang = editor ? editor.selectedLanguage : item.default_language || config.defaultLanguage
        editor = {
          edit: null,
          org: null,
          currentMeta: null,
          selectedLanguage: lang
        }

        Vue.set(state.edits, item.id, editor);
        Vue.set(state.edits[item.id], 'org', cloneDeep(item));
        Vue.set(state.edits[item.id], 'edit', item);

        if(item.meta && item.meta.length > 0) {
          const meta = item.meta.filter(m => m.language == lang)
          const defMeta = {language: lang}
          const currentMeta = meta.length > 0 ? meta[0] : defMeta
          Vue.set(state.edits[item.id], 'currentMeta', currentMeta);
        }
      }
    },

    deleteEditItemMeta(state, {id, lang}) {
      let item
      const editor = state.edits[id]

      if(editor) {
        item = editor.org
        if(item.meta && item.meta.length > 0) {
          item.meta = item.meta.filter(m => m.language != lang)
        }

        item = editor.edit
        if(item.meta && item.meta.length > 0) {
          item.meta = item.meta.filter(m => m.language != lang)

          if(editor.currentMeta && editor.currentMeta.language == lang) {
            const defMeta = item.meta.filter(m => m.language == item.default_language)

            if(defMeta.length > 0) editor.currentMeta = defMeta
            else editor.currentMeta = {language: item.default_language}

            editor.selectedLanguage = editor.currentMeta.language
          }
        }
      }
    },

    putNewMeta(state, {itemId, meta}) {
      const editor = state.edits[itemId]
      if(editor) {
        editor.edit.meta = editor.edit.meta || []
        editor.edit.meta = editor.edit.meta.filter(m => m.language != meta.language)
        editor.edit.meta.push(meta)
        Vue.set(editor, 'currentMeta', meta);
      }
    },

    putCurrentMeta(state, {itemId, meta}) {
      const editor = state.edits[itemId]
      if(editor) {
        if(editor.currentMeta && editor.edit.meta.length > 0) {
          editor.edit.meta = editor.edit.meta.map(m => m.language == meta.language ? meta : m)
        }
        Vue.set(editor, 'currentMeta', meta);
      }
    },

    syncCurrentMeta(state, itemId) {
      const editor = state.edits[itemId]
      if(editor && editor.currentMeta && editor.edit.meta.length > 0) {
        const meta = editor.currentMeta
        editor.edit.meta = editor.edit.meta.map(m => m.language == meta.language ? meta : m)
      }
    },

    syncEditItem(state, itemId) {
      const editor = state.edits[itemId]
      if(editor) {
        editor.org = cloneDeep(editor.edit)
      }
    },

    updateEditItem(state, updates) {
      for(const itemId in updates) {
        const editor = state.edits[itemId]
        if(editor) {
          const update = updates[itemId]
          for(const field in update) {
            Vue.set(editor.edit, field, update[field]);
          }
        }
      }
    },

    updateEditMeta(state, updates) {
      for(const itemId in updates) {
        const editor = state.edits[itemId]
        if(editor) {
          const update = updates[itemId]
          for(const field in update) {
            Vue.set(editor.currentMeta, field, update[field]);
          }
        }
      }
    },

    setLanguageEditMeta(state, {id, lang}) {
      const editor = state.edits[id]
      if(editor) {
        editor.selectedLanguage = lang
      }
    },

    clearEditItem(state, itemId) {
      Vue.delete(state.edits, itemId);
    },

    updateItem(state, item) {
      let expanded
      state.items = state.items.map(i => i.id == item.id ? item : i)

      expanded = state.expanded[item.id]
      if(expanded) {
        const children = item.children ? item.children : expanded.children
        item.children = children
        state.expanded[item.id] = cloneDeep(item)
      }

      expanded = state.expanded[item.parent]
      if(expanded) {
        if(expanded.children && expanded.children.length > 0) {
          expanded.children = expanded.children.map(i => i.id == item.id ? item : i)
        }

        if(expanded.dashboard && expanded.dashboard.length > 0) {
          expanded.dashboard = expanded.dashboard.map(i => i.id == item.id ? item : i)
        }

        state.expanded[item.id] = expanded
      }
    },

    putOptions(state, options) {
      for(const opt in options) {
        state.options[opt] = options[opt]
      }
    },

    updateNewItem(state, update) {
      for(const key in update) {
        state.new.item[key] = update[key]
      }
    },

    initNewItem(state, opts) {
      opts = opts || {}
      const init = opts.init || {}
      const remember = opts.remember || {}

      const type = remember.type ? state.new.item.type : init.type || null

      let serial_layout = init.serial_layout || state.new.item.serial_layout;
      if(!serial_layout && (type == 'magazine' || type == 'series')) {
        serial_layout = 'grid_dropdown'
      }

      let defParentId = null,
        defDefaultLang = config.defaultLanguage,
        defPartner = null,
        defAssetSource = helpers.hasAssets(type) ? "internal" : null,
        defPremiumValue = includes(state.premiumTypes, type) ? 100 : 0

      if(init.parent) {
        state.new.hasParent = true

        defParentId = init.parent.id
        defDefaultLang = init.parent.default_language
        defPartner = init.parent.partner_id
        defAssetSource = helpers.getAssetSource(init.parent.asset_format)
      }
      else state.new.hasParent = false

      state.new.item = {
        id: init.id || null,
        reporting_code: init.reporting_code || null,
        upc: init.upc || null,
        isrc: init.isrc || [],
        parent: remember.parent ? state.new.item.parent : defParentId,
        title: remember.title ? state.new.item.title : init.title || null,
        type: type,
        serial_nr: init.serial_nr || null,
        serial_layout: serial_layout,
        is_embedded: init.is_embedded == true,
        partner_id: remember.partner_id ? state.new.item.partner_id : init.partner_id || defPartner,
        artist: remember.artist ? state.new.item.artist : init.artist || null,
        artists: remember.artists ? state.new.item.artists : init.artists || [],
        genres: remember.genres ? state.new.item.genres : init.genres || [],
        no_rev_share: remember.no_rev_share ? state.new.item.no_rev_share : init.no_rev_share || null,
        premium_value: defPremiumValue,
        default_language: remember.default_language ? state.new.item.default_language : init.default_language || defDefaultLang,
        asset_source: remember.asset_source ? state.new.item.asset_source : init.asset_source || defAssetSource,
        labels: remember.labels ? state.new.item.labels : init.labels || [],
        plan: remember.plan ? state.new.item.plan : init.plan || state.defaultPlan,
        quality_stamp: remember.quality_stamp ? state.new.item.quality_stamp : init.quality_stamp || false,
        year: remember.year ? state.new.item.year : init.year || null,
        schedule_from: remember.schedule_from ? state.new.item.schedule_from : init.schedule_from || new Date().toISOString(),
        schedule_to: remember.schedule_to ? state.new.item.schedule_to : init.schedule_to || null,
        geo_whitelist: remember.geo_whitelist ? state.new.item.geo_whitelist : init.geo_whitelist || ["world"],
        geo_blacklist: remember.geo_blacklist ? state.new.item.geo_blacklist : init.geo_blacklist || [],
        category_type: remember.category_type ? state.new.item.category_type : init.category_type || "dynamic",
        category_ad_mode: remember.category_ad_mode ? state.new.item.category_ad_mode : init.category_ad_mode || "default",
        link_to: remember.link_to ? state.new.item.link_to : init.link_to || "",
        recently_added_exclude: false,
        call_to_action_enabled: false,
        shopping_enabled: remember.shopping_enabled ? state.new.item.shopping_enabled : init.shopping_enabled || true,
        show_meta_text: true,
        cover_enabled: true,
        page_enabled: true,
        header_overflow: true,
        slide_enabled: config.slideEnabled,
        allow_archiving: true,
        is_kids_content: remember.is_kids_content ? state.new.item.is_kids_content : init.is_kids_content || false,
        is_livestream: remember.is_livestream ? state.new.item.is_livestream : init.is_livestream || false,
        datetime_livestream_start: init.datetime_livestream_start || new Date().toISOString(),
        datetime_livestream_end: init.datetime_livestream_end || null
      }
    },

    putTypeFilter(state, typeFilter) {
      if(state.searchMode) state.searchTypeFilter = (typeFilter || {})
      else state.typeFilter = (typeFilter || {})
    },

    updateTypeFilter(state, update) {
      if(state.searchMode) state.searchTypeFilter[update.type] = update.value
      else state.typeFilter[update.type] = update.value
    },

    typeFilterSetAll(state, setAll) {
      if(state.searchMode) {
        for(const type in state.searchTypeFilter) {
          state.searchTypeFilter[type] = setAll
        }
      }
      else {
        for(const type in state.typeFilter) {
          state.typeFilter[type] = setAll
        }
      }
    },

    typeFilterSetAllWhenAllOff(state) {
      let allOff = true
      const typeFilter = state.searchMode ? state.searchTypeFilter : state.typeFilter
      for(const type in typeFilter) {
        if(typeFilter[type]) allOff = false
      }

      if(allOff) {
        for(const type in typeFilter) {
          typeFilter[type] = true
        }
      }
    },

    putLabelFilter(state, labelFilter) {
      state.labelFilter = labelFilter
    },

    updateLabelFilter(state, update) {
      state.labelFilter = difference(union(state.labelFilter, update.toAdd || []), update.toRemove || [])
    },

    putArtistFilter(state, artistFilter) {
      state.artistFilter = artistFilter
    },

    updateArtistFilter(state, update) {
      state.artistFilter = difference(union(state.artistFilter, update.toAdd || []), update.toRemove || [])
    },

    putGenreFilter(state, genreFilter) {
      state.genreFilter = genreFilter
    },

    updateGenreFilter(state, update) {
      state.genreFilter = difference(union(state.genreFilter, update.toAdd || []), update.toRemove || [])
    },

    putPublishStatusFilter(state, publishStatusFilter) {
      state.publishStatusFilter = (publishStatusFilter || {})
    },

    updatePublishStatusFilter(state, update) {
      state.publishStatusFilter[update.publish_status] = update.value
    },

    publishStatusFilterSetAll(state, setAll) {
      for(const publishStatus in state.publishStatusFilter) {
        state.publishStatusFilter[publishStatus] = setAll
      }
    },

    publishStatusFilterSetAllWhenAllOff(state) {
      let allOff = true
      for(const publishStatus in state.publishStatusFilter) {
        if(state.publishStatusFilter[publishStatus]) allOff = false
      }

      if(allOff) {
        for(const publishStatus in state.publishStatusFilter) {
          state.publishStatusFilter[publishStatus] = true
        }
      }
    },

    putPartnerFilter(state, partnerFilter) {
      state.partnerFilter = partnerFilter
    },

    partnerFilterReset(state) {
      state.partnerFilter = "__all__"
    },

    updateSearchFilter(state, input) {
      if(input) {
        state.searchFilter = new RegExp(helpers.escapeRegExp(input), "gi")
        state.searchFilterString = input
      }
      else {
        state.searchFilter = null
        state.searchFilterString = null
      }
    },

    updateSearchCategories(state, input) {
      if(input) {
        state.searchCategoriesString = input
      }
      else {
        state.searchCategoriesString = null
      }
    },

    updateSearchMedia(state, input) {
      if(input) {
        state.searchMediaString = input
      }
      else {
        state.searchMediaString = null
      }
    },


    putSearchMode(state, searchMode) {
      state.searchMode = searchMode
      state.options.sortOrder = util.sortOrderOptions(searchMode)
      state.options.categorySortOrder = util.categorySortOrderOptions(searchMode)

      if(searchMode) {
        state.sortOrder = "ranked"
        state.sorter = util.getSortFunction("ranked")

        state.categorySortOrder = "ranked"
        state.categorySorter = util.getSortFunction("ranked")
      }
      else if(state.sortOrder == "ranked") {
        state.sortOrder = "title-asc"
        state.sorter = util.getSortFunction("title-asc")

        state.categorySortOrder = "title-asc"
        state.categorySorter = util.getSortFunction("title-asc")
      }
    },

    putSortOrder(state, sortOrder) {
      state.sortOrder = sortOrder
      state.sorter = util.getSortFunction(sortOrder)
    },

    putCategorySortOrder(state, sortOrder) {
      state.categorySortOrder = sortOrder
      state.categorySorter = util.getSortFunction(sortOrder)
    },

    putSelectedNodes(state, selectedNodes) {
      state.selectedNodes = selectedNodes
    },

    putManagedLabels(state, labels) {
      state.managedLabels = labels
    },

    putManagedArtists(state, artists) {
      state.managedArtists = artists
    },

    putManagedGenres(state, genres) {
      state.managedGenres = genres
    },

    requestArchive(state, ids) {
      state.toArchiveMediaIds = ids
      state.modals.archive.visible = true
    },

    resetArchive(state) {
      state.toArchiveMediaIds = null
      state.modals.archive.visible = false
    },

    updateModal(state, update) {
      for(const modal in update) {
        state.modals[modal] = update[modal]
      }
    },

    copySelectedCategory(state) {
      state.categoryIdToCopy = state.selectedNodes[0].media.id
    },

    resetCategoryIdToCopy(state) {
      state.categoryIdToCopy = null
    }
  },

  actions: {
    async getID() {
      const resp = await fetch("/admin/api/v2/media/generate-id")
      const json = await helpers.JSONHandler(resp)
      return json.data
    },

    async fetchPremiumValueOptions({commit}) {
      let resp = await fetch(`/admin/api/v2/media/premium-value-options`)
      let json = await helpers.JSONHandler(resp)
      commit('putPremiumValueOptions', json.data)

      resp = await fetch(`/admin/api/v2/media/premium-types`)
      json = await helpers.JSONHandler(resp)
      commit('putPremiumTypes', json.data)
    },

    async fetchPlanOptions({commit}) {
      let resp = await fetch(`/admin/api/v2/media/plan-options`)
      let json = await helpers.JSONHandler(resp)
      commit('putPlanOptions', json.data.options)
      commit('putDefaultPlan', json.data.default)
    },

    async fetchTranscodeOptions({commit}) {
      let opts = {}

      const resp1 = await fetch(`/admin/api/v2/assets/default-transcode-preset/audio`)
      const json1 = await helpers.JSONHandler(resp1)
      opts.defaultAudioPreset = json1.data

      const resp2 = await fetch(`/admin/api/v2/assets/default-transcode-preset/video`)
      const json2 = await helpers.JSONHandler(resp2)
      opts.defaultVideoPreset = json2.data

      const resp3 = await fetch(`/admin/api/v2/assets/transcode-presets/audio`)
      const json3 = await helpers.JSONHandler(resp3)
      opts.audioPresets = json3.data

      const resp4 = await fetch(`/admin/api/v2/assets/transcode-presets/video`)
      const json4 = await helpers.JSONHandler(resp4)
      opts.videoPresets = json4.data

      commit('putTranscodeOptions', opts)
    },


    async fetchItems({commit}, opts) {
      opts = opts || {}
      let reqArgs = ''
      reqArgs = opts.types && opts.types.length > 0 ? `?type=${opts.types.join(',')}` : '?type=audio,video,series,book,magazine,music_album,podcast,brand,page,external,photo_package,artist_page,playlist_page'
      reqArgs = opts.status && opts.status.length > 0 ? reqArgs + `&status=${opts.status.join(',')}` : reqArgs + '&status=draft,ready,published'
      reqArgs = opts.embedded == true || opts.embedded == false ? reqArgs + `&embedded=${opts.embedded}` : reqArgs + '&embedded=false'
      reqArgs = opts.labels && opts.labels.length > 0 ? reqArgs + `&labels=${opts.labels.join(',')}` : reqArgs
      reqArgs = opts.partner ? reqArgs + `&provider=${opts.partner}` : reqArgs

      const resp = await fetch(`/admin/api/v2/media${reqArgs}`)
      const json = await helpers.JSONHandler(resp)
      commit('putItems', json.data)
    },

    async fetchCategories({commit}) {
      const resp = await fetch('admin/api/v2/media/root?status=draft,ready,published')
      const json = await helpers.JSONHandler(resp)
      commit('putCategories', json.data)
    },

    async fetchExpandedItem({commit}, itemId) {
      const resp = await fetch(`admin/api/v2/media/${itemId}?mode=expanded&status=draft,ready,published`)
      const json = await helpers.JSONHandler(resp)
      commit('putExpandedItem', json.data)
    },

    async fetchEditItem(context, itemId) {
      const resp = await fetch(`admin/api/v2/media/${itemId}?mode=expanded&all_meta&status=draft,ready,published`)
      return await helpers.JSONHandler(resp)
    },

    async fetchItem(context, itemId) {
      const resp = await fetch(`admin/api/v2/media/${itemId}?status=draft,ready,published`)
      return await helpers.JSONHandler(resp)
    },

    async searchCategories({commit}, input) {
      commit('updateSearchCategories', input)

      if(input && input.length > 1) {
        const resp = await fetch(`admin/api/v2/media/search?status=draft,ready,published&type=category&embedded=false&mode=expand_parents&language=${config.defaultLanguage}&q=${input}`)
        const json = await helpers.JSONHandler(resp)
        commit('putCategories', json.data)
      }
    },

    async searchMedia({commit, dispatch}, input) {
      commit('updateSearchMedia', input)

      if(input && input.length > 1) {
        const types = "album_track,audio,book,brand,episode,external,issue,magazine,music_album,page,artist_page,playlist_page,photo_package,photo,podcast_episode,podcast,product_category,product,season,series,video,year"
        const resp = await fetch(`admin/api/v2/media/search?status=draft,ready,published&embedded=false&type=${types}&language=${config.defaultLanguage}&q=${input}`)
        const json = await helpers.JSONHandler(resp)
        commit('putItems', json.data)
      }
      else return dispatch('handleSearchFilterUpdate')
    },

    async persistExpandedItemReordering({state, commit}, id) {
      const expanded = state.expanded[id]
      if(expanded && expanded.children.length > 0) {
        const update = expanded.children.map((m, ndx) => {
          m.serial_nr = ndx + 1
          return {
            id: m.id,
            type: m.type,
            default_language: m.default_language,
            serial_nr: ndx + 1
          }
        })

        const payload = JSON.stringify({data: update})
        const resp = await fetch('/admin/api/v2/media?no_post_process&_no_search_index', { method: 'POST', body: payload })
        await helpers.JSONHandler(resp)

        commit('applyExpandedItemReordering', id)
      }

    },

    async fetchPartnerOptions({commit}) {
      const resp = await fetch("/admin/api/v2/partners/select-options")
      const json = await helpers.JSONHandler(resp)
      commit('putOptions', { partner: json.data })
    },

    async fetchPartnerFilterOptions({commit}) {
      const resp = await fetch("/admin/api/v2/partners/select-options?include-all")
      const json = await helpers.JSONHandler(resp)
      commit('putOptions', { partnerFilter: json.data })
    },

    async fetchLanguageOptions({commit}) {
      const resp = await fetch('/admin/api/v2/users/lang-options')
      const json = await helpers.JSONHandler(resp)
      commit('putOptions', { language: json.data })
    },

    async fetchOptions({dispatch}) {
      await dispatch('fetchPartnerOptions')
      await dispatch('fetchPartnerFilterOptions')
      dispatch('fetchLanguageOptions')
    },

    async archive({state, dispatch}, {id, isCategory}) {
      const payload = JSON.stringify({data: id})
      const resp = await fetch('/admin/api/v2/media/archive', { method: 'POST', body: payload })

      await helpers.JSONHandler(resp)
      if(isCategory) return await dispatch('fetchCategories')
      else return await dispatch('fetchItems', {partner: state.partnerFilter})
    },

    async archiveSelected({commit, state, dispatch}) {
      let ids = []

      if(helpers.isArray(state.toArchiveMediaIds) && state.toArchiveMediaIds.length > 0) {
        ids = state.toArchiveMediaIds
      }
      else {
        ids = state.selectedNodes.map(node => node.media.id)
      }

      commit('resetArchive')
      const payload = JSON.stringify({data: ids})
      const resp = await fetch('/admin/api/v2/media/archive', { method: 'POST', body: payload })

      await helpers.JSONHandler(resp)
      return await dispatch('fetchItems', {partner: state.partnerFilter})
    },

    async publishCheck({state}) {
      let ids = state.selectedNodes.map(node => node.media.id)
      const payload = JSON.stringify({data: ids})
      const resp = await fetch('/admin/api/v2/media/publish-check', { method: 'POST', body: payload })

      return helpers.JSONHandler({resp: resp, ids: ids})
    },

    async publishCheckResults({commit}, data) {
      data.headerText =  data.isSaveResult ? 'Live item, but data is incomplete!' : 'Some issues where found with at least one item!'
      data.subHeaderText = data.isSaveResult ? 'Please review the results carefully and if you still want to save this item, click OK.' : ''
      commit('updateModal', {publishCheckResults: {visible: true, data: data}})
    },

    async setPartner({state, commit, dispatch}, partnerId) {
      if(partnerId != state.partnerFilter) {
        await dispatch('fetchItems', {partner: partnerId})
        commit('putPartnerFilter', partnerId)
      }
    },

    async setNewNoRevShare({commit}, partnerId) {
      const resp = await fetch(`/admin/api/v2/partners/${partnerId}/default-no-rev-share`)
      const json = await helpers.JSONHandler(resp)
      commit('updateNewItem', {no_rev_share: json.data})
    },

    async setNewPremiumValue({commit}, partnerId) {
      const resp = await fetch(`/admin/api/v2/partners/${partnerId}/default-premium-value`)
      const json = await helpers.JSONHandler(resp)
      commit('updateNewItem', {premium_value: json.data})
    },

    async setSearchMode({state, commit, dispatch}, searchMode) {
      if(state.searchMode != searchMode) {
        if(searchMode) {
          if(state.searchCategoriesString && state.searchCategoriesString.length > 1) {
            dispatch('searchCategories', state.searchCategoriesString)
          }
          else {
            commit('putCategories', [])
          }

          if(state.searchMediaString && state.searchMediaString.length > 1) {
            dispatch('searchMedia', state.searchMediaString)
          }
          else {
            commit('putItems', [])
          }
        }
        else {
          dispatch('fetchCategories')
          dispatch('fetchItems')
        }

        commit('putSearchMode', searchMode)
      }
    },

    async getNewSerialNr(context, opts) {
      if(opts.parent && opts.type) {
        const resp = await fetch(`/admin/api/v2/media/${opts.parent}/new-child-serial-nr/${opts.type}`)
        const json = await helpers.JSONHandler(resp)

        return json.data
      }
      else return null
    },

    async initNewItem({commit, dispatch}, opts) {
      const id = await dispatch('getID')

      let initArgs = {id: id, ...opts.init}

      if(initArgs.parent) {
        const serial_nr = await dispatch('getNewSerialNr', {parent: initArgs.parent.id, type: initArgs.type})
        initArgs.serial_nr = serial_nr
      }

      commit('initNewItem', {init: initArgs, remember: opts.remember})
    },

    async storeDdexItem({dispatch}, itemProperties) {
      const id = await dispatch('getID')
      itemProperties.id = id
      const item = itemProperties;
      if(item.title || item.type == 'season' || item.type == 'year') {

        let data = {
          id: item.id,
          partner_id: item.partner_id,
          parent: item.parent,
          artist: item.artist,
          artists: item.artists,
          genres: item.genres,
          serial_nr: item.serial_nr,
          meta: {title: item.title, language: item.default_language},
          default_language: item.default_language,
          type: item.type,
          labels: item.labels || [],
          plan: item.plan,
          no_rev_share: !!item.no_rev_share,
          premium_value: item.premium_value,
          year: item.year,
          schedule_from: item.schedule_from,
          schedule_to: item.schedule_to,
          geo_whitelist: item.geo_whitelist,
          geo_blacklist: item.geo_blacklist,
          status: "draft",
          cover_enabled: true,
          page_enabled: true,
          slide_enabled: config.slideEnabled,
          embedded_dashboard_position: "bottom",
          allow_archiving: true,
          recently_added_exclude: item.recently_added_exclude,
          call_to_action_enabled: item.call_to_action_enabled,
          shopping_enabled: item.shopping_enabled,
          is_kids_content: item.is_kids_content,
          show_meta_text: (item.show_meta_text == undefined ? true : item.show_meta_text)
        }

        if(helpers.hasAssets(item.type)) {
          data.asset_format = util.assetFormat(item)
        }

        if(helpers.isLivestreamable(item.type)) {
          data.is_livestream = item.is_livestream
          data.datetime_livestream_start = item.datetime_livestream_start
          data.datetime_livestream_end = item.datetime_livestream_end
        }

        if(item.type == 'series' || item.type == 'magazine') {
          data.serial_layout = item.serial_layout
        }


        data.artist = item.artist

        if(item.type == 'category' || item.type == 'playlist_page') {
          data.category_type = item.category_type
          data.category_ad_mode = item.type == 'playlist_page' ? "playlist" : item.category_ad_mode
          data.category_layout = {}
          data.category_layout.format = "cover"
          data.category_layout.size = config.defaultCoverSize || "md"
          data.category_layout.formfactor = item.type == 'playlist_page' ? "square" : "portrait"
          data.category_layout.show_titles = false
        }

        if(item.type == 'external') {
          data.meta.link_to = item.link_to
        }

        if(item.type == 'photo_package') {
          data.show_meta_text = false
        }

        if(!this.hasParent) {
          data.quality_stamp = item.quality_stamp
        }

        if(helpers.hasUPC(item.type)) {
          data.upc = item.upc
        }

        if(helpers.hasISRC(item.type)) {
          data.isrc = item.isrc
          data.reporting_code = item.reporting_code
        }

        const payload = JSON.stringify({data: data})
        const resp = await fetch(`/admin/api/v2/media?init_fields=${item.type != 'photo_package'}`, { method: 'POST', body: payload })
        await helpers.JSONHandler(resp)
        console.log("RESPONSE INFO:")
        console.log(resp)
        await dispatch('fetchItems')
        return id
      }
    },

    async addNewItem({commit, dispatch}, init) {
      await dispatch('initNewItem', {init})

      if(init.type == 'photo_package') {
        await dispatch('prepersistNewItem')
      }

      commit('updateModal', {new: {visible: true}})
    },

    async persistNewItem({dispatch, state}, itemProperties) {
      const item = itemProperties || state.new.item;

      if(item.title || item.type == 'season' || item.type == 'year') {

        let data = {
          id: item.id,
          partner_id: item.partner_id,
          parent: item.parent,
          serial_nr: item.serial_nr,
          meta: {title: item.title, secondary_title: item.secondary_title, language: item.default_language},
          default_language: item.default_language,
          type: item.type,
          labels: item.labels || [],
          plan: item.plan,
          no_rev_share: !!item.no_rev_share,
          premium_value: item.premium_value,
          year: item.year,
          schedule_from: item.schedule_from,
          schedule_to: item.schedule_to,
          geo_whitelist: item.geo_whitelist,
          geo_blacklist: item.geo_blacklist,
          status: "draft",
          cover_enabled: true,
          page_enabled: config.pageEnabled,
          header_overflow: !!item.header_overflow,
          slide_enabled: config.slideEnabled,
          embedded_dashboard_position: "bottom",
          allow_archiving: true,
          recently_added_exclude: item.recently_added_exclude,
          call_to_action_enabled: item.call_to_action_enabled,
          shopping_enabled: item.shopping_enabled,
          is_kids_content: item.is_kids_content,
          show_meta_text: (item.show_meta_text == undefined ? true : item.show_meta_text)
        }

        if(helpers.hasAssets(item.type)) {
          data.asset_format = util.assetFormat(item)
        }

        if(helpers.isLivestreamable(item.type)) {
          data.is_livestream = item.is_livestream
          data.datetime_livestream_start = item.datetime_livestream_start
          data.datetime_livestream_end = item.datetime_livestream_end
        }

        if(item.type == 'series' || item.type == 'magazine') {
          data.serial_layout = item.serial_layout
        }

        if(item.type == 'music_album' || item.type == 'album_track' || config.enableArtistsEverywhere) {
          data.artist = item.artist
          data.artists = item.artists
        }

        if(config.enableGenres) {
          data.genres = item.genres
        }

        if(item.type == 'category' || item.type == 'playlist_page') {
          data.category_type = item.category_type
          data.category_ad_mode = item.type == 'playlist_page' ? "playlist" : item.category_ad_mode
          data.category_layout = {}
          data.category_layout.format = "cover"
          data.category_layout.size = config.defaultCoverSize || "md"
          data.category_layout.formfactor = item.type == 'playlist_page' ? "square" : "portrait"
          data.category_layout.show_titles = false
        }

        if(item.type == 'external') {
          data.meta.link_to = item.link_to
        }

        if(item.type == 'photo_package') {
          data.show_meta_text = false
        }

        if(!this.hasParent) {
          data.quality_stamp = item.quality_stamp
        }

        if(helpers.hasUPC(item.type)) {
          data.upc = item.upc
        }

        if(helpers.hasISRC(item.type)) {
          data.isrc = item.isrc
          data.reporting_code = item.reporting_code
        }

        const payload = JSON.stringify({data: data})
        const resp = await fetch(`/admin/api/v2/media?init_fields=${item.type != 'photo_package'}`, { method: 'POST', body: payload })
        await helpers.JSONHandler(resp)

        if(helpers.isTopLevel(item.type) && item.type != 'category') {
          return dispatch('fetchItems', {partner: state.partnerFilter})
        }
        else if(state.expanded[item.parent]) {
          return dispatch('fetchExpandedItem', item.parent)
        }
      }
    },

    async prepersistNewItem({state}) {
      const item = state.new.item

      let data = {
        id: item.id,
        default_language: item.default_language,
        type: item.type,
        status: "archived",
        meta: {
          language: item.default_language
        }
      }

      const payload = JSON.stringify({data: data})
      const resp = await fetch('/admin/api/v2/media?init_fields=false&skip_publish_check=true', { method: 'POST', body: payload })
      return helpers.JSONHandler(resp)
    },


    async showEditor({dispatch, commit}, id) {
      const json = await dispatch('fetchEditItem', id)
      commit('putEditItem', json.data)
    },

    async closeEditor({getters, commit}, id) {
      const diff = getters.editDiff(id)
      console.log(diff)
      if(diff) throw "unsaved-edits"

      commit('clearEditItem', id)

    },

    async setLanguageEditor({state, commit}, {id, lang}) {
      const editor = state.edits[id]

      if(editor) {
        const allMeta = editor.edit.meta || []
        const newCurrentMeta = allMeta.filter(m => m.language == lang)

        if(newCurrentMeta[0]) {
          commit('putCurrentMeta', {itemId: id, meta: newCurrentMeta[0]})
        }
        else {
          commit('putNewMeta', {itemId: id, meta: {language: lang}})
        }

        commit('setLanguageEditMeta', {id, lang})
      }
    },

    async saveEdit({getters, dispatch, commit}, {id, skipPublishCheck}) {
      commit('syncCurrentMeta', id)
      const diff = getters.editDiff(id)
      if(diff) {
        await dispatch('persistItem', {media: diff, skipPublishCheck: skipPublishCheck})
        commit('syncEditItem', id)
      }
      else return Promise.resolve("no-change")
    },

    async deleteEditMeta({commit, state}, {lang, id}) {
      const editor = state.edits[id]

      if(editor) {
        const resp = await fetch(`admin/api/v2/media/${id}/${lang}`, {method: 'DELETE'})
        await helpers.JSONHandler(resp)
        commit('deleteEditItemMeta', {lang, id})
      }
    },

    async persistItem({commit}, {media, skipPublishCheck}) {
      const payload = JSON.stringify({data: media})
      const resp = await fetch(`/admin/api/v2/media${skipPublishCheck ? '?skip_publish_check' : ''}`, { method: 'POST', body: payload })
      const json = await resp.json()

      if(resp.status != 200) {
        throw {text: resp.statusText, status: resp.status || resp.resp.status, json: json}
      }

      const respUpdate = await fetch(`/admin/api/v2/media/${media.id}`)
      const jsonUpdate = await respUpdate.json()

      if(resp.status != 200) {
        throw {text: resp.statusText, status: resp.status || resp.resp.status, json: jsonUpdate}
      }

      commit('updateItem', jsonUpdate.data)

      return jsonUpdate.data
    },

    async updateItems({commit}, updates) {
      const payload = JSON.stringify({data: updates})
      const resp = await fetch('/admin/api/v2/media?skip_publish_check', { method: 'POST', body: payload })
      const json = await helpers.JSONHandler(resp)

      updates.forEach(async item => {
        const resp = await fetch(`/admin/api/v2/media/${item.id}`)
        const jsonUpdate = await helpers.JSONHandler(resp)
        commit('updateItem', jsonUpdate.data)
      })

      return json
    },

    async duplicateItem({commit}, id) {
      commit('updateModal', {duplicate: {id: id, visible: true}})
    },

    async persistDuplicates({state, dispatch}, count) {
      const payload = JSON.stringify({data: {
        count: count,
        id: state.modals.duplicate.id
      }})
      const resp = await fetch('/admin/api/v2/media/duplicate', { method: 'POST', body: payload })
      await helpers.JSONHandler(resp)
      return await dispatch('fetchItems', {partner: state.partnerFilter})
    },

    async publishMedia({state, dispatch}, {ids, recursive}) {
      const payload = JSON.stringify({data: ids})
      const resp = await fetch(`/admin/api/v2/media/publish?recursive=${recursive}`, { method: 'POST', body: payload })
      await helpers.JSONHandler(resp)
      dispatch('fetchItems', {partner: state.partnerFilter})

      if(recursive) {
        if(helpers.isArray(ids)) {
          ids.forEach(id => dispatch('fetchExpandedItem', id))
        }
        else {
          dispatch('fetchExpandedItem', ids)
        }
      }
    },

    async unpublishMedia({state, dispatch}, {ids, recursive}) {
      const payload = JSON.stringify({data: ids})
      const resp = await fetch(`/admin/api/v2/media/unpublish?recursive=${recursive}`, { method: 'POST', body: payload })
      await helpers.JSONHandler(resp)
      dispatch('fetchItems', {partner: state.partnerFilter})

      if(recursive) {
        if(helpers.isArray(ids)) {
          ids.forEach(id => dispatch('fetchExpandedItem', id))
        }
        else {
          dispatch('fetchExpandedItem', ids)
        }
      }
    },

    async exportSelectedNodes({state}) {
      let ids = state.selectedNodes.map(node => node.media.id)
      const payload = JSON.stringify({data: {ids: ids, as_tree: true}})
      const resp = await fetch('/admin/api/v2/media/download-export', { method: 'POST', body: payload })

      return await helpers.JSONHandler(resp)
    },

    async filterMediaLabels({commit}) {
      commit('updateModal', {labels: {visible: true}})
    },

    async manageMediaLabels({state, commit}) {
      let labels = null
      state.selectedNodes.forEach(node => {
        if(labels == null) labels = node.media.labels
        else labels = labels.filter(label => includes(node.media.labels, label))
      })
      if(labels) {
        commit('putManagedLabels', labels)
        commit('updateModal', {labels: {visible: true}})
      }
    },

    async mutateMediaLabels({state, dispatch}, mut) {
      let updates = []
      state.selectedNodes.forEach(node => {
        updates.push({
          id: node.media.id,
          default_language: node.media.default_language,
          type: node.media.type,
          labels: union(difference(node.media.labels, mut.toRemove), mut.toAdd)
        })
      })

      await dispatch('updateItems', updates)
      return await dispatch('fetchItems', {partner: state.partnerFilter})
    },

    async filterMediaArtists({commit}) {
      commit('updateModal', {artists: {visible: true, allowManage: false, allowNew: false}})
    },

    async manageMediaArtists({state, commit}) {
      let artists = null
      state.selectedNodes.forEach(node => {
        if(artists == null) artists = node.media.artists
        else artists = artists.filter(artist => includes(node.media.artists, artist))
      })
      if(artists) {
        commit('putManagedArtists', artists)
        commit('updateModal', {artists: {visible: true, allowManage: true, allowNew: true}})
      }
    },

    async mutateMediaArtists({state, dispatch}, mut) {
      let updates = []
      state.selectedNodes.forEach(node => {
        updates.push({
          id: node.media.id,
          default_language: node.media.default_language,
          type: node.media.type,
          artists: union(difference(node.media.artists, mut.toRemove), mut.toAdd)
        })
      })

      await dispatch('updateItems', updates)
      return await dispatch('fetchItems', {partner: state.partnerFilter})
    },

    async filterMediaGenres({commit}) {
      commit('updateModal', {genres: {visible: true, allowManage: false, allowNew: false}})
    },

    async manageMediaGenres({state, commit}) {
      let genres = null
      state.selectedNodes.forEach(node => {
        if(genres == null) genres = node.media.genres
        else genres = genres.filter(genre => includes(node.media.genres, genre))
      })
      if(genres) {
        commit('putManagedGenres', genres)
        commit('updateModal', {genres: {visible: true, allowManage: true, allowNew: true}})
      }
    },

    async mutateMediaGenres({state, dispatch}, mut) {
      let updates = []
      state.selectedNodes.forEach(node => {
        updates.push({
          id: node.media.id,
          default_language: node.media.default_language,
          type: node.media.type,
          genres: union(difference(node.media.genres, mut.toRemove), mut.toAdd)
        })
      })

      await dispatch('updateItems', updates)
      return await dispatch('fetchItems', {partner: state.partnerFilter})
    },

    async manageMediaProperties({commit}) {
      commit('updateModal', {properties: {visible: true}})
    },

    async mutateMediaProperties({state, dispatch}, mut) {
      let updates = []
      state.selectedNodes.forEach(node => {
        let def_update = {
          id: node.media.id,
          default_language: node.media.default_language,
          type: node.media.type
        }
        let update = {}

        const type = node.media.type
        for (const [key, value] of Object.entries(mut)) {
          if(value != null) {
            switch(key) {
              case "no_rev_share":
                if(helpers.hasNoRevShare(type)) {
                  update[key] = value
                }
                break

              case "plan":
                if(helpers.hasPlan(type)) {
                  update[key] = value
                }
                break

              case "premium_value":
                if(helpers.hasPremiumValue(type)) {
                  update[key] = value
                }
                break

              case "quality_stamp":
                if(helpers.hasQualityStamp(type)) {
                  update[key] = value
                }
                break

              case "show_meta_text":
                if(helpers.hasShowMetaText(type)) {
                  update[key] = value
                }
                break

              case "cover_enabled":
                if(helpers.hasCoverEnabled(type)) {
                  update[key] = value
                }
                break

              case "slide_enabled":
                if(helpers.hasSlideEnabled(type)) {
                  update[key] = value
                }
                break

              case "ra_exclude":
                if(helpers.hasRAExclude(type)) {
                  update[key] = value
                }
                break

              case "shopping_enabled":
                if(helpers.hasShoppingEnabled(type)) {
                  update[key] = value
                }
                break

              case "call_to_action_enabled":
                if(helpers.hasCallToAction(type)) {
                  update[key] = value
                }
                break

              default:
                update[key] = value
            }
          }
        }

        if(Object.keys(update).length != 0) {
          updates.push(merge(def_update, update))
        }
      })

      console.log(updates)

      await dispatch('updateItems', updates)
      return await dispatch('fetchItems', {partner: state.partnerFilter})
    },

    async pasteCategory({state, commit, dispatch}) {
      const idToPaste = state.categoryIdToCopy
      const selectedNode = state.selectedNodes[0]
      const parentId = selectedNode.childrenVisible ? selectedNode.media.id : selectedNode.media.parent
      const serialNr = selectedNode.childrenVisible ? 0 : selectedNode.media.serial_nr + 1

      if(idToPaste == parentId) {
        throw "error"
      }
      else {
        const payload = JSON.stringify({data: {id: idToPaste, parent_id: parentId, serial_nr: serialNr}})
        const resp = await fetch('/admin/api/v2/media/duplicate-category', { method: 'POST', body: payload })
        await helpers.JSONHandler(resp)

        commit('resetCategoryIdToCopy')
        return await dispatch('fetchCategories')
      }
    },

    async handleSearchFilterUpdate({state, getters, commit, dispatch}) {
      if(state.searchMode) {
        const hasQuery = state.searchMediaString && state.searchMediaString.length > 0
        const hasLabels = state.labelFilter && state.labelFilter.length > 0
        const hasSomeTypes = !(getters.typeAllSet || getters.typeNoneSet)

        if(hasLabels || hasSomeTypes) {
          const opts = {
            types: util.getActiveTypes(state.searchTypeFilter),
            labels: state.labelFilter
          }
          return await dispatch('fetchItems', opts)
        }
        else if(!hasQuery) commit('putItems', [])
      }
    },

    async setLabelFilter({commit, dispatch}, labelFilter) {
      commit('putLabelFilter', labelFilter)

      return await dispatch('handleSearchFilterUpdate')
    },

    async updateLabelFilter({commit, dispatch}, update) {
      commit('updateLabelFilter', update)

      return await dispatch('handleSearchFilterUpdate')
    },

    async updateTypeFilter({commit, dispatch}, update) {
      commit('updateTypeFilter', update)

      return await dispatch('handleSearchFilterUpdate')
    }





//    showAddNew({commit}, )
  }
}
