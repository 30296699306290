<template>
  <div class="add-media">
    <b-modal  id="add-media-modal"
              ref="modal"
              v-model="visible"
              :size="(hasParent && (mediaType != 'category' || mediaType != 'playlist_page')) ? '' : 'xl'"
              :title="title"
              :ok-disabled="!((mediaType == 'category' || mediaPartner) && mediaTitle && (photoFileCount > 0 || mediaType != 'photo_package'))"
              no-close-on-esc
              no-close-on-backdrop
              @shown="handleFocus"
              @ok="createMedia">
      <div class="form-content">
        <b-form id="edit-form">
          <b-form-group label="ID" label-for="id-input" horizontal :label-cols="2">
            <b-input id="id-input" :value="mediaId" readonly></b-input>
          </b-form-group>

          <template v-if="!hasParent">
            <b-form-group label="Content Provider" label-for="partner-select" horizontal :label-cols="2">
              <b-form-select id="partner-select" v-model="mediaPartner" :options="$store.state.media.options.partner" />
             </b-form-group>
          </template>

          <b-form-group v-if="mediaType == 'photo_package'" label="Select File" label-for="file-input" horizontal :label-cols="2" class="overflow-hidden">
            <span class="text-left">
              <S3Uploader
                ref="uploader"
                :itemId="mediaId"
                name="image.primary"
                format="image"
                :multiple="false"
                :nameAsPath="false"
                @finished="handleFinished"
                @upload-state="photoUploadState = $event"
                @file-count="handleFileCount"
                @start-upload-request="$event.handler()"
                @progress="handleUploadProgress"

              />
            </span>
          </b-form-group>

          <template v-if="$config.useExternalIDs">
            <b-form-group v-if="$helpers.hasUPC(mediaType)" label="UPC" label-for="upc-input" horizontal :label-cols="2">
              <b-form-input id="upc-input" v-model="mediaUPC" ></b-form-input>
            </b-form-group>
            <b-form-group v-if="$helpers.hasISRC(mediaType)" label="ISRC" label-for="isrc-input" horizontal :label-cols="2">
              <b-form-textarea id="isrc-input" v-model="mediaISRC" ></b-form-textarea>
            </b-form-group>
            <b-form-group v-if="$helpers.hasISRC(mediaType)" label="Reporting Code" label-for="reporting-code-input" horizontal :label-cols="2">
              <b-form-input id="reporting-code-input" v-model="mediaReportingCode" ></b-form-input>
            </b-form-group>
          </template>

          <template v-if="(mediaType == 'music_album' || mediaType == 'album_track' || $config.enableArtistsEverywhere) && mediaType != 'category'" >
            <b-form-group label="Artists" label-for="artists-comp" horizontal :label-cols="2">
              <LabelsInput id="artists-comp" :language="mediaDefaultLang" :labels="mediaArtists" @update="handleArtists" label-type="artist"/>
            </b-form-group>
          </template>

          <b-form-group label="Title" label-for="title-input" horizontal :label-cols="2">
            <b-form-input ref="titleInput" id="title-input" v-model="mediaTitle" ></b-form-input>
          </b-form-group>

          <b-form-group label="Secondary Title" label-for="sec-title-input" horizontal :label-cols="2">
            <b-form-input id="sec-title-input" v-model="mediaSecTitle" ></b-form-input>
          </b-form-group>

          <template v-if="$helpers.isPlayable(mediaType) || $helpers.isTopLevel(mediaType)">
            <b-form-group label="Plan" label-for="plan-select" horizontal :label-cols="2">
              <b-form-select id="plan-select" v-model="mediaPlan" :options="$store.state.media.options.plan"/>
            </b-form-group>
          </template>

          <template v-if="$helpers.isPlayable(mediaType)">
            <b-form-group label="Revshare TOA" label-for="no-rev-share-checkbox" horizontal :label-cols="2">
              <b-form-checkbox id="no-rev-share-checkbox" v-model="mediaNoRevShare" v-b-tooltip.hover title="Puts revenue share to own account" switch />
            </b-form-group>
            <b-form-group v-if="$config.usePremiumValue && mediaPlan != 'free' && isPremiumType(mediaType)" label="Premium Value" label-for="premium-value-select" horizontal :label-cols="2">
              <b-form-select id="premium-value-select" v-model.number="mediaPremiumValue" :options="$store.state.media.options.premiumValue"/>
            </b-form-group>
          </template>


          <template v-if="mediaType == 'external'">
            <b-form-group label="Link to" label-for="link-input" horizontal :label-cols="2">
              <b-form-input id="link-input" v-model="mediaLinkTo" ></b-form-input>
            </b-form-group>
          </template>

          <b-form-group v-if="$helpers.hasAssets(mediaType)" label="Asset source" label-for="asset-source-select" horizontal :label-cols="2">
            <b-form-select id="asset-source-select" v-model="mediaAssetSource" :options="$store.state.media.options.asset" />
          </b-form-group>

          <template v-if="$config.enableGenres && mediaType != 'category'">
            <b-form-group label="Genres" label-for="genres-comp" horizontal :label-cols="2">
              <LabelsInput id="genres-comp" :language="mediaDefaultLang" :labels="mediaGenres" @update="handleGenres" label-type="genre"/>
            </b-form-group>
          </template>

          <template v-if="!hasParent">
            <b-form-group label="Fallback Language" label-for="default-language-select" horizontal :label-cols="2">
              <b-form-select id="default-language-select" v-model="mediaDefaultLang" :options="$store.state.media.options.language" />
            </b-form-group>
            <b-form-group label="Labels" label-for="labels-comp" horizontal :label-cols="2">
              <LabelsInput id="labels-comp" :language="mediaDefaultLang" :labels="mediaLabels" @update="handleLabels"/>
            </b-form-group>

            <template v-if="mediaType == 'photo_package'">
              <b-form-group label="Enable Shopping" label-for="shopping-enabled-checkbox" horizontal :label-cols="2">
                <b-form-checkbox id="shopping-enabled-checkbox" v-model="mediaShoppingEnabled" switch />
              </b-form-group>
            </template>
            <template v-else-if="mediaType == 'playlist_page'">
              <b-form-group label="Type" label-for="category-type-select" horizontal :label-cols="2">
                <b-form-select id="category-type-select" v-model="mediaCategoryType" :options="$store.state.media.options.playlistPageCategoryType"/>
              </b-form-group>
            </template>          
            <template v-else>
              <b-form-group v-if="$helpers.isPlayableTopLevel(mediaType)" label="Kids Content" label-for="is-kids-content-checkbox" horizontal :label-cols="2">
                <b-form-checkbox id="is-kids-content-checkbox" v-model="mediaIsKidsContent" switch />
              </b-form-group>
              <b-form-group label="Quality Stamp" label-for="quality-stamp-checkbox" horizontal :label-cols="2">
                <b-form-checkbox id="quality-stamp-checkbox" v-model="mediaQualityStamp" switch />
              </b-form-group>
              <b-form-group label="Enable Slides" label-for="slide-enabled-checkbox" horizontal :label-cols="2">
                <b-form-checkbox id="slide-enabled-checkbox" v-model="mediaSlideEnabled" switch />
              </b-form-group>
            </template>

            <b-form-group label="Year of Release" label-for="year-input" horizontal :label-cols="2">
              <b-form-input id="year-input" v-model.number="mediaYear"></b-form-input>
            </b-form-group>

            <b-form-group label="Schedule" label-for="publication-comp" horizontal :label-cols="2">
              <Publication id="publication-comp" v-model="item"/>
            </b-form-group>

            <template v-if="$helpers.isLivestreamable(mediaType)">
                <b-form-group label="Is Livestream" horizontal :label-cols="2">
                  <b-form-checkbox id="is-livestream-checkbox" v-model="mediaIsLivestream" switch />
                  <div v-if="mediaIsLivestream" class="mt-2">
                    <Publication id="datetime-livestream-comp" v-model="item" from-field="datetime_livestream_start" to-field="datetime_livestream_end"/>
                  </div>
                </b-form-group>
            </template>


            <b-form-group label="Geo Whitelist" label-for="whitelist-comp" horizontal :label-cols="2">
              <Geos id="whitelist-comp" :url="geosUrl" v-model="mediaGeoWhitelist"/>
            </b-form-group>
            <b-form-group label="Geo Blackist" label-for="blacklist-comp" horizontal :label-cols="2">
              <Geos id="blacklist-comp" :url="geosUrl" v-model="mediaGeoBlacklist"/>
            </b-form-group>
          </template>

          <template v-else-if="mediaType == 'category'">
            <b-form-group label="Enable More Page" label-for="page-enabled-checkbox" horizontal :label-cols="2">
              <b-form-checkbox id="page-enabled-checkbox" v-model="mediaPageEnabled" switch />
            </b-form-group>
            <b-form-group label="Type" label-for="category-type-select" horizontal :label-cols="2">
              <b-form-select id="category-type-select" v-model="mediaCategoryType" :options="$store.state.media.options.categoryType"/>
            </b-form-group>
            <b-form-group label="Ad Mode" label-for="category-ad-mode-select" horizontal :label-cols="2">
              <b-form-select id="category-ad-mode-select" v-model="mediaCategoryAdMode" :options="$store.state.media.options.categoryAdMode"/>
            </b-form-group>
            <b-form-group label="Fallback Language" label-for="default-language-select" horizontal :label-cols="2">
              <b-form-select id="default-language-select" v-model="mediaDefaultLang" :options="$store.state.media.options.language" />
            </b-form-group>
            <b-form-group label="Schedule" label-for="publication-comp" horizontal :label-cols="2">
              <Publication id="publication-comp" v-model="item"/>
            </b-form-group>
            <b-form-group label="Geo Whitelist" label-for="whitelist-comp" horizontal :label-cols="2">
              <Geos id="whitelist-comp" :url="geosUrl" v-model="mediaGeoWhitelist"/>
            </b-form-group>
            <b-form-group label="Geo Blackist" label-for="blacklist-comp" horizontal :label-cols="2">
              <Geos id="blacklist-comp" :url="geosUrl" v-model="mediaGeoBlacklist"/>
            </b-form-group>
          </template>

        </b-form>

        <template v-if="photoUploadState == 'uploading'">
          <b-progress :value="photoUploadProgressValue" :max="photoUploadProgressMax" show-progress animated style="width: 200px" height="40px"></b-progress>
        </template>


      </div>
    </b-modal>
  </div>
</template>

<script>
import Geos from "./Geos.vue"
import Publication from "./Publication.vue"
import S3Uploader from "./S3Uploader.vue"
import LabelsInput from "./LabelsInput.vue"

export default {
  name: 'AddMedia',

  components: {
    Geos,
    Publication,
    S3Uploader,
    LabelsInput
  },

  data() {
    return {
      photoFileCount: 0,
      photoUploadState: "ready",
      photoUploadProgressValue: 0,
      photoUploadProgressMax: 1
    }
  },

  computed: {
    title() {
      return "Add " + this.$helpers.prettify(this.mediaType)
    },

    geosUrl() {
      return "/admin/api/v2/media/geo-options"
    },

    hasParent() {
      return this.$store.state.media.new.hasParent
    },

    visible: {
      get() {
        return this.$store.state.media.modals.new.visible
      },
      set(value) {
        this.$store.commit('media/updateModal', {new: {visible: value}})
      }
    },

    item: {
      get() {
        return this.$store.state.media.new.item
      },
      set(value) {
        this.$store.commit('media/updateNewItem', value)
      }
    },

    mediaId: {
      get() {
        return this.$store.state.media.new.item.id
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {id: value})
      }
    },
    mediaParentId: {
      get() {
        return this.$store.state.media.new.item.parent
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {parent: value})
      }
    },
    mediaArtist: {
      get() {
        return this.$store.state.media.new.item.artist
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {artist: value})
      }
    },
    mediaArtists: {
      get() {
        return this.$store.state.media.new.item.artists
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {artists: value})
      }
    },
    mediaGenres: {
      get() {
        return this.$store.state.media.new.item.genres
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {genres: value})
      }
    },
    mediaTitle: {
      get() {
        return this.$store.state.media.new.item.title
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {title: value})
      }
    },
    mediaSecTitle: {
      get() {
        return this.$store.state.media.new.item.secondary_title
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {secondary_title: value})
      }
    },
    mediaUPC: {
      get() {
        return this.$store.state.media.new.item.upc
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {upc: value})
      }
    },
    mediaISRC: {
      get() {
        return this.$store.state.media.new.item.isrc.join('\r\n')
      },
      set(value) {
        if(value) {
          const values = this.$helpers.linebreakSplit(value).map(s => s.trim())
          this.$store.commit('media/updateNewItem', {isrc: values})
        }
      }
    },
    mediaReportingCode: {
      get() {
        return this.$store.state.media.new.item.reporting_code
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {reporting_code: value})
      }
    },
    mediaType: {
      get() {
        return this.$store.state.media.new.item.type
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {type: value})
      }
    },
    mediaPartner: {
      get() {
        return this.$store.state.media.new.item.partner_id
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {partner_id: value})
      }
    },
    mediaNoRevShare: {
      get() {
        return this.$store.state.media.new.item.no_rev_share
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {no_rev_share: value})
      }
    },
    mediaDefaultLang: {
      get() {
        return this.$store.state.media.new.item.default_language
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {default_language: value})
      }
    },
    mediaAssetSource: {
      get() {
        return this.$store.state.media.new.item.asset_source
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {asset_source: value})
      }
    },
    mediaLabels: {
      get() {
        return this.$store.state.media.new.item.labels
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {labels: value})
      }
    },
    mediaPlan: {
      get() {
        return this.$store.state.media.new.item.plan
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {plan: value})
      }
    },
    mediaPremiumValue: {
      get() { return this.$store.state.media.new.item.premium_value },
      set(value) {
        this.$store.state.media.new.item.premium_value = parseInt(value)
      }
    },
    mediaIsKidsContent: {
      get() {
        return this.$store.state.media.new.item.is_kids_content
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {is_kids_content: value})
      }
    },
    mediaQualityStamp: {
      get() {
        return this.$store.state.media.new.item.quality_stamp
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {quality_stamp: value})
      }
    },
    mediaYear: {
      get() {
        return this.$store.state.media.new.item.year
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {year: value})
      }
    },
    mediaIsLivestream: {
      get() {
        return this.$store.state.media.new.item.is_livestream
      },

      set(val) {
        this.$store.commit('media/updateNewItem', {is_livestream: val})
      }
    },
    mediaGeoWhitelist: {
      get() {
        return this.$store.state.media.new.item.geo_whitelist
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {geo_whitelist: value})
      }
    },
    mediaGeoBlacklist: {
      get() {
        return this.$store.state.media.new.item.geo_blacklist
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {geo_blacklist: value})
      }
    },
    mediaCategoryAdMode: {
      get() {
        return this.$store.state.media.new.item.category_ad_mode
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {category_ad_mode: value})
      }
    },
    mediaCategoryType: {
      get() {
        return this.$store.state.media.new.item.category_type
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {category_type: value})
      }
    },
    mediaLinkTo: {
      get() {
        return this.$store.state.media.new.item.link_to
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {link_to: value})
      }
    },
    mediaPageEnabled: {
      get() {
        return this.$store.state.media.new.item.page_enabled
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {page_enabled: value})
      }
    },

    mediaSlideEnabled: {
      get() {
        return this.$store.state.media.new.item.slide_enabled
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {slide_enabled: value})
      }
    },

    mediaShoppingEnabled: {
      get() {
        return this.$store.state.media.new.item.shopping_enabled
      },
      set(value) {
        this.$store.commit('media/updateNewItem', {shopping_enabled: value})
      }
    },

    assetObjectName() {
      return ``
    }

  },

  watch: {
    mediaPartner(val) {
      if(this.$helpers.isPlayable(this.mediaType)) {
        this.$store.dispatch('media/setNewNoRevShare', val)
        this.$store.dispatch('media/setNewPremiumValue', val)
      }
    },
  },

  methods: {
    isPremiumType(mediaType) {
      return this.$_.includes(this.$store.state.media.premiumTypes, mediaType)
    },

    saveEditWhitelist(whitelist) {
      let pub = this.$store.state.media.new.item.publication
      pub.geo_whitelist = whitelist
      this.$store.commit('media/updateNewItem', {publication: pub})
    },

    saveEditBlacklist(blacklist) {
      let pub = this.$store.state.media.new.item.publication
      pub.geo_blacklist = blacklist
      this.$store.commit('media/updateNewItem', {publication: pub})
    },

    createMedia(ev) {
      this.$store.dispatch('media/persistNewItem')
        .then(() => this.$root.app.showAlert("Create item successful", 'success'))
        .catch(error => this.$root.app.showAlert(error.text, 'danger'))

      if(this.mediaType == 'photo_package') {
        this.$refs.uploader.startUpload()
        ev.preventDefault()
      }
    },

    handleFocus() {
      if(this.mediaType == 'photo_package') {
        this.$refs.uploader.show()
      }
      else {
        this.$refs.titleInput.focus()
      }
    },

    handleFinished() {
      this.$bvModal.hide("add-media-modal")
    },

    handleFileCount(count) {
      this.photoFileCount = count;
    },

    handleUploadProgress({max, value}) {
      this.photoUploadProgressMax = max
      this.photoUploadProgressValue = value
    },

    handleLabels(labels) {
      this.mediaLabels = labels
    },

    handleArtists(artists) {
      this.mediaArtists = artists
    },
    handleGenres(genres) {
      this.mediaGenres = genres
    },
  }
}
</script>


<style scoped>
#edit-form {
  text-align: right
}

.form-content {
  margin: 1rem;
}

</style>

