export default {
  name: "Real Madrid Demo",

  defaultLanguage: "en",

  use2FA: true,

  usePremiumValue: false,

  useExternalIDs: false,

  slideEnabled: true,

  enableAds: false,

  enableShopping: true,

  enableAffiliates: true,

  enablePromoCodes: true,

  hasPayingCustomers: false,

  allowInsertFromExcel: false,

  allowPartnerContractUpload: false,

  logo: "platform/logo.png",

  thumbor_server: "real-test-img.usendes.com"
}
